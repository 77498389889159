import { useForm, ValidationError } from "@formspree/react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Contact = () => {
const navigate = useNavigate();
  useEffect(() => {
    document.title = "لينكر | تواصل معنا";
  }, []);
  const [state, handleSubmit] = useForm("xpzvwpjp");
  if (state.succeeded) {
    setTimeout(() => {
      navigate('/')
    }, 5000);
    return (
    <div style={{height:"90vh"}} className="d-flex justify-content-center align-items-center" >
    <p className="alert alert-success">شكرا علي تواصل معنا سيتم الرد عليك في اقرب وقت </p>
    </div>
    );
  }




  return (
    <>
      <section className="bg-light py-3 py-md-5">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6">
              <h2 className="mb-4 display-5 text-center"> تواصل معنا </h2>
              <hr className="w-50 mx-auto mb-5 mb-xl-9 border-dark-subtle" />
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row justify-content-lg-center">
            <div className="col-12 col-lg-9">
              <div className="bg-white border rounded shadow-sm overflow-hidden">
                <form onSubmit={handleSubmit}>
                  <div className="row gy-4 gy-xl-5 p-4 p-xl-5">
                    <div className="col-12 col-md-12">
                      <label htmlFor="email" className="form-label">
                        البريد الإلكتروني{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <div className="input-group">
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          name="email"
                          required
                        ></input>
                        <ValidationError
                          prefix="Email"
                          field="email"
                          errors={state.errors}
                        />
                      </div>
                    </div>

                    <div className="col-12">
                      <label htmlFor="message" className="form-label">
                        الرسالة <span className="text-danger">*</span>
                      </label>
                      <textarea
                        className="form-control"
                        id="message"
                        name="message"
                        rows="3"
                        required
                      ></textarea>
                      <ValidationError
                        prefix="Message"
                        field="message"
                        errors={state.errors}
                      />
                    </div>
                    <div className="col-12">
                      <div className="d-grid">
                        <button
                          type="submit"
                          disabled={state.submitting}
                          className="btn  btn-lg"
                          style={{backgroundColor:"#FF8400", color:"#fff"}}
                        >
                          إرسال
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
