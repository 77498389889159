// AuthContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';

const AuthContext = createContext();


export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setAuthenticated] = useState(
    localStorage.getItem('isAuthenticated') === 'true'
  );
  const [token, setToken] = useState(localStorage.getItem('token') || '');

  const login = (newToken) => {
    // Add your login logic here
    localStorage.setItem('isAuthenticated', 'true');
    localStorage.setItem('Token', newToken);
    setAuthenticated(true);
    setToken(newToken);
  };

  const logout = () => {
    // Add your logout logic here
    localStorage.removeItem('isAuthenticated');
    localStorage.removeItem('isAuthor');
    localStorage.removeItem('isPub');
    localStorage.removeItem('Token');
    localStorage.removeItem('UserId');
    


    setAuthenticated(false);
    setToken('');
  };

  // Check authentication on initial load
  useEffect(() => {
    const storedAuth = localStorage.getItem('isAuthenticated') === 'true';
    const storedToken = localStorage.getItem('token') || '';
    if (storedAuth !== isAuthenticated || storedToken !== token) {
      setAuthenticated(storedAuth);
      setToken(storedToken);
    }
  }, [isAuthenticated, token]);

  return (
    <AuthContext.Provider value={{ isAuthenticated, token, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
