import React, { useEffect, useLayoutEffect, useState } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { ToastContainer, toast } from 'react-toastify';

import { CgProfile } from "react-icons/cg";
import { FaBook } from "react-icons/fa";
import { CiEdit } from "react-icons/ci";


//Assets
import styles from "../../styles/profile.module.css";
import style from "../../styles/books.module.css";

import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Footer from "../../layouts/footer";
import { BarLoader } from "react-spinners";
import UpdateProfile from "./UpdateAuther";

const AuthProfile = () => {
  const [value, setValue] = useState(0);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const Token = localStorage.getItem("Token");
  const UserId = localStorage.getItem("UserID");


       //Modal
       const [show, setShow] = useState(false);

       const handleClose = () => setShow(false);
       const handleShow = () => setShow(true);


  const [userData, setUserData] = useState({
    id: "",
    full_name: "",
    phone: "",
    email: "",
    last_login: "",
    created_at: "",
    user_auther: {
      id: "",
      birth_date: "",
      nationalty: "",
      living_home: "",
      cv: null,
      job: "",
      education: "",
      whatapp_num: "",
      is_publish_same_contry: "",
      fav_contact: "",
      is_published_before: "",
    },
    user_author_old_books: [],
    user_books: [],
  });
  const [Name, setName] = useState("");
  const [Email, setEmail] = useState("");
  const [Phone, setPhone] = useState("");
  let navigate = useNavigate();
  
  const fetchData = async () => {
    try {
      setLoading(true);

      const response = await axios.get(
        "https://linker-pub.com/linkerapis/public/api/Profile-Author",
        {
          headers: {
            Authorization: `Bearer ${Token}`,
            "x-api-key": "mwDA9w",
            "Content-Language": "ar",
            "Content-Type": "application/json",
          },
        }
      );

      console.log("API Response:", response);
      if (response.data.data.id == UserId) {
      }
      // Log the data to the console for inspection
      console.log("API Response:", response.data);

      setUserData(response.data.data);
    } catch (error) {
      setError(error);
      console.error("Error fetching User Data:", error);
    } finally {
      setLoading(false);
      console.log("Fetched successfully");
    }
    setName(userData.full_name);
    setEmail(userData.email);
    setPhone(userData.phone)
    console.log(userData.full_name);
  };

  useEffect(() => {
    fetchData();
   
  }, []);

  const truncateDescription = (description, wordLimit) => {
    if (description) {
      const words = description.split(" ");
      const truncatedWords = words.slice(0, wordLimit);
      return truncatedWords.join(" ") + (words.length > wordLimit ? "..." : "");
    } else {
      return ""; // or handle it in another way based on your requirements
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handelDeleteAccount = async (e) => {
    e.preventDefault();
      const response = await axios.post(
        "https://linker-pub.com/linkerapis/public/api/Delete-Account",null,
        {
          headers: {
            Authorization: `Bearer ${Token}`,
            "x-api-key":"mwDA9w",
            "Content-Language":"ar",
            "Content-Type":"application/json"
            
          },
        }
      );
        console.log(response , Token);
        if(response.data.success){
            localStorage.clear();
            navigate('/')
            toast("response.data.message");
        }
    }

    useEffect(() => {
      document.title = "لينكر | حسابي";
    }, []);
  return (
    <>
    <ToastContainer />
      {error ? (
        <div style={{ height: "90vh" }}>
          <div
            style={{ height: "100vh" }}
            className="d-flex flex-column justify-content-center align-items-center"
          >
            <div
              style={{ width: "600px", fontSize: "20px" }}
              className="alert alert-danger text-center mt-3 px-5 "
              role="alert"
            >
              {error}
            </div>
            {/* You can add additional error handling/display logic here */}
            <Link className="btn btn-danger" to="/login">
              {" "}
              تسجيل دخول{" "}
            </Link>
          </div>
        </div>
      ) : (
        <>
          {loading && (
            <BarLoader className="mt-2" color="#FF7C05" width="100%" />
          )}
          {/* <h1>{userData.full_name}</h1> */}

          <Container className="mt-5">
            <div className="d-flex justify-content-center">
              <h1>{userData.full_name}</h1>
            </div>

            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <div className={styles.tabs}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    variant="scrollable" 
                    scrollButtons="auto" 
                  >
                    <Tab
                      className={styles.tab}
                      label={
                        <div className="d-flex">
                          <CgProfile />
                          <h5 className={styles.tabText}>
                            {" بيانات المؤلف "}
                          </h5>
                        </div>
                      }
                      {...a11yProps(0)}
                    />

                    <Tab
                      className={styles.tab}
                      label={
                        <div className="d-flex">
                          <FaBook />
                          <h5 className={styles.tabText}>{" مؤلفاتي "}</h5>
                        </div>
                      }
                      {...a11yProps(1)}
                    />

                    <Tab
                      className={styles.tab}
                      label={
                        <div className="d-flex">
                          <CiEdit />
                          <h5 className={styles.tabText}>{" تعديل الحساب  "}</h5>
                        </div>
                      }
                      {...a11yProps(2)}
                    />
                  </Tabs>
                </div>
              </Box>
              <CustomTabPanel value={value} index={0}>
                <div className={styles.authData}>
                  <div className={styles.hrHalf}></div>
                  <div className={styles.hrtext}>بيانات المؤلف</div>
                  <div className={styles.hrHalf}></div>
                </div>

                <div className={styles.data}>
                  <div
                    className={` justify-content-center ${styles.datafilds}`}
                  >
                    <div style={{ width: "74%" }} className={styles.dataFild}>
                      <h4>الإسم </h4>
                      <hr />
                      <h5>{userData.full_name}</h5>
                    </div>
                  </div>

                  <div className={styles.datafilds}>
                    <div className={styles.dataFild}>
                      <h4>المهنة</h4>
                      <hr />
                      <h5>{userData.user_auther.job}</h5>
                    </div>

                    <div className={styles.dataFild}>
                      <h4>تاريخ الميلاد</h4>
                      <hr />
                      <h5>{userData.user_auther.birth_date}</h5>
                    </div>
                  </div>

                  <div className={styles.datafilds}>
                    <div className={styles.dataFild}>
                      <h4>الجنسية</h4>
                      <hr />
                      <h5>{userData.user_auther.nationalty}</h5>
                    </div>

                    <div className={styles.dataFild}>
                      <h4>بلد الاقامة</h4>
                      <hr />
                      <h5>{userData.user_auther.living_home}</h5>
                    </div>
                  </div>

                  <div className={styles.datafilds}>
                    <div className={styles.dataFild}>
                      <h4>السيرة الذاتية</h4>
                      <hr />
                      <h5>
                        <a target="_blank" href={userData.user_auther.cv}>
                          {" "}
                          السيرة الذاتية{" "}
                        </a>
                      </h5>
                    </div>

                    <div className={styles.dataFild}>
                      <h4>المؤهل الدراسي</h4>
                      <hr />
                      <h5>{userData.user_auther.education}</h5>
                    </div>
                  </div>

                  <div className={styles.authData}>
                    <div className={styles.hrHalf}></div>
                    <div className={styles.hrtext}>عن المؤلف</div>
                    <div className={styles.hrHalf}></div>
                  </div>
                </div>

                <div className={styles.data}>


                  <div className={styles.datafildsRow}>
                    <div className={styles.dataFildRow}>
                      <h4>الطريقة الأفضل للتواصل </h4>
                      <hr />
                      <h5>

                      {userData.user_auther.fav_contact}

                      </h5>
                    </div>
                  </div>

                  <div className={styles.datafildsRow}>
                    <div className={styles.dataFildRow}>
                      <h4>المؤلف لديه كتب نشرت سابقًا </h4>
                      <hr />
                      {userData.user_auther.is_published_before === 'yes' ? (
          'نعم'
        ) : (
          // Display something else or leave it empty
          'لا'
        )}

                      <ul className={styles.UL}>
                        {userData.user_author_old_books.map((book) => (
                          <li key={book.id}>{book.book_name}</li>
                        ))}
                      </ul>
                    </div>
                  </div>

                  <div className={styles.authData}>
                    <div className={styles.hrHalf}></div>
                    <div className={styles.hrtext}>بيانات التواصل</div>
                    <div className={styles.hrHalf}></div>
                  </div>

                  <div className={styles.datafildsRow}>
                    <div className={styles.dataFildRow}>
                      <h4>رقم WhatsApp</h4>
                      <hr />
                      <h5>{userData.user_auther.whatapp_num}</h5>
                    </div>
                  </div>

                  <div className={styles.datafildsRow}>
                    <div className={styles.dataFildRow}>
                      <h4>رقم الهاتف</h4>
                      <hr />
                      <h5>{userData.phone}</h5>
                    </div>
                  </div>

                  <div className={styles.datafildsRow}>
                    <div className={styles.dataFildRow}>
                      <h4> البريد الإلكتروني </h4>
                      <hr />
                      <h5>{userData.email}</h5>
                    </div>
                  </div>
                </div>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <Row className="mt-5 pt-5 justify-content-center">
                  {userData.user_books.map((book) => (
                    <div
                      onClick={() => navigate(`/book/${book.id}`)}
                      style={{ width: "auto" }}
                    >
                      <div key={book.id} className={style.mainNotbook}>
                        <h3> {book.book_title} </h3>
                        <hr />
                        <h6> {book.book_main_category} </h6>
                        <hr />
                        <p>
                          {" "}
                          {truncateDescription(book.book_description, 30)}{" "}
                        </p>
                      </div>
                    </div>
                  ))}
                </Row>
              </CustomTabPanel>

              <CustomTabPanel value={value} index={2}>
                <Row className="mt-5 pt-5 justify-content-center">
                <UpdateProfile
  fullName={userData.full_name}
  email={userData.email}
  phone={userData.phone}
/>
                      <Button style={{width: "300px"}}  className="btn btn-danger" onClick={handleShow} > حذف الحساب  </Button>


                      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>حذف الحساب</Modal.Title>
        </Modal.Header>
        <Modal.Body>يرجي تاكيد حذف الحساب </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            رجوع
          </Button>
          <Button variant="danger" onClick={() => { handleClose(); handelDeleteAccount(); }}>
            تاكيد
          </Button>
        </Modal.Footer>
      </Modal>







                </Row>
              </CustomTabPanel>
              

            </Box>
          </Container>
        </>
      )}
    </>
  );
};

export default AuthProfile;
