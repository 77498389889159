import React, { useEffect, useState } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";

// import styles from "../../assets/css/home.module.css";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import spinnerstyles from '../../styles/spinner.module.css'



const UpdateBook = () => {

  const [loading, setLoading] = useState(false);

    const { book_title,bookId,book_description,sub_cat,book_number,book_main_category } = useParams();
    console.log(book_title,bookId,book_description,sub_cat)

  const [shouldRender, setShouldRender] = useState(true);
  const isAuthor = localStorage.getItem("isAuthor");
  console.log(isAuthor)
  const navigate = useNavigate();



  /*Post Api Vars */
  const [bookName, setBookName] = useState("");
  const [selectedCategoryId, setSelectedCategoryId] = useState("");


  const [selectedSubcategoryId, setSelectedSubcategoryId] = useState("");
  const [catId,setCatId]=useState("")



  const [description, setDescription] = useState("");

  /*Handle Caty and sub caty */
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);

  const [pageNumbers, setpageNumbers] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [bookSuccess , setBookSuccess] = useState('');
  /*Handle Caty and sub caty */

  //Token to set Auth for Post API
  const Token = localStorage.getItem("Token");

    const fetchData = async () => {

        try {
            const res = await axios.get('https://linker-pub.com/linkerapis/public/api/Categories' , {
              headers: {
                "Content-Type": "application/json",
                "x-api-key": "mwDA9w",
              },
            });
            if (res.status === 200) {
              console.log(res);
              setCategories(res.data.data)
            } else {
              console.error('Authentication failed:', res.data.message);
              console.log(res);
      
            }
          } catch (error) {
            console.error('Error Fetching Categorys:', error.message);
          }
        };
    

  useEffect(() => {
    setBookName(book_title)
    // setSelectedCategoryId(bookId)
    setDescription(book_description)
    setSelectedSubcategoryId(sub_cat)

   
  
      fetchData();
  }, []);


  useEffect(() => {
    setShouldRender(isAuthor);
  }, [isAuthor]);

  let changeit= (e) => setSelectedCategoryId(e.target.value)


  const handleCategoryChange = (e) => {
    const selectedCategoryId = e.target.value;
    // console.log("sss", e.target.value)
    // console.log(selectedCategoryId); // Add this line
    setSelectedCategory( e.target.value);
    changeit(e);

    const selectedCategoryData = categories.find(
      (category) => category.id === parseInt(selectedCategoryId, 10)
    );
    setSubcategories(
      selectedCategoryData ? selectedCategoryData.sub_categories : []
    );

  };
  useEffect(()=>{
    categories.forEach((category) => {
        category.sub_categories?.forEach((subcategory) => {
          if (subcategory.name === selectedSubcategoryId) {
              console.log(subcategory , selectedSubcategoryId);
              setCatId(subcategory.id);
              // return;
            
          }
        });
      });
  },[selectedSubcategoryId])

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    

   
    
   
    const postData = {
      book_title: bookName,
      book_category_id: Number(selectedCategory),
      book_description: description,
      sub_cat: Number(catId),
      book_number:pageNumbers
    };

    try {
      setLoading(true);
      console.log(postData)
      const data = await axios.post(
        `https://linker-pub.com/linkerapis/public/api/Book/update/${bookId}`,
        postData,
        {
          headers: {
            Authorization: `Bearer ${Token}`,
            "x-api-key": "mwDA9w",
            'Content-Language' : 'ar'
          },
        }
      );
    if(data.data.success){
        toast("تم تعديل الكتاب بنجاح ");
        navigate(`/book/${bookId}`)
    }    
    } catch (error) {
      console.error("Error updating book:", error);
    } finally{
      setLoading(false);

    }
  };



  useEffect(() => {
    document.title = `لينكر |  تعديل الكتاب` ;
  }, []);

  return (
    <div>
                  {loading && (
              <div className={spinnerstyles.overlay}>
                <div className={spinnerstyles.spinner}></div>
              </div>
            )}
   <ToastContainer />
      {shouldRender  ? (
        <>
          <Container className="mt-5">
            <Row className="justify-content-center">
              <Col className="m-auto" md={{ span: 6, offset: 3 }}>
                <h2>تعديل كتاب </h2>
                <Form>
                  <Form.Group controlId="bookName">
                    <Form.Label>عنوان الكتاب</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="اضف عنوان الكتاب"
                      name="bookName"
                      value={bookName}
                      onChange={(e) => setBookName(e.target.value)}
                      required
                    />
                  </Form.Group>

                  <Form.Group controlId="category">
                    <Form.Label>التخصص</Form.Label>
                    <Form.Control
                      as="select"
                      name="category"
                      onChange={handleCategoryChange}
                      value={selectedCategoryId}
                      required
                    >
                      <option value="" defaultValue>
                        اختر التخصص
                      </option>
                      {categories?.map((category) => (
                        <option key={category.id} value={category.id}>
                          {category.name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>

                  <Form.Group controlId="subcategory">
                    <Form.Label>التخصص الفرعي</Form.Label>
                    <Form.Control
                      as="select"
                      name="subcategory"
                      value={selectedSubcategoryId}
                      onChange={(e) => setSelectedSubcategoryId(e.target.value)}
                      required
                    >
                      <option value="">اختر التخصص الفرعي</option>
                      {subcategories.map((subcategory) => (
                        <option key={subcategory.id} value={subcategory.name}>
                          {subcategory.name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>

                  <Form.Group controlId="bookName">
                    <Form.Label> عدد الصفحات</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder={book_number}
                      name="bookName"
                      value={pageNumbers}
                      onChange={(e) => setpageNumbers(e.target.value)}
                      required
                    />
                  </Form.Group>


                  <Form.Group controlId="description">
                    <Form.Label>نبذة عن الكتاب</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={4}
                      placeholder="اضف نبذة قصيرة عن الكتاب"
                      name="description"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      required
                    />
                  </Form.Group>
                  <div className="mt-5 text-center">
                    <Button
                      onClick={handleFormSubmit}
                    //   className={styles.homeButtons}
                      variant="primary"
                      type="submit"
                    >
                      تعديل
                    </Button>
                  </div>
                </Form>
              </Col>
            </Row>
          </Container>
        </>
      ) : (
        <h1>You Are Not Allowd </h1>
      )}
    </div>
  );
};

export default UpdateBook;
