import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";

import styles from "../../styles/books.module.css";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { BarLoader } from "react-spinners";

const Books = () => {

    //Auth Variables 
  const isPub = localStorage.getItem("isPub") == "true";
  const isAuthor = localStorage.getItem("isAuthor") == "true";
  const isAuth = localStorage.getItem("isAuthenticated");
  const Token = localStorage.getItem("Token");

  //Search Params 
  const [searchTerm, setSearchTerm] = useState("");
  const [category, setCategory] = useState("all");
  const [subCategory, setSubCategory] = useState("all");
  const [categoriesData, setCategoriesData] = useState([]);

  //Page URL Param 
  const [page , setPage] = useState(1)

  //Has More Data 
  const[moreData , setMoreData] = useState(true);
  const [noMoreDataText , setNoMoreDataText] = useState('')



  //Get Sub_Caty Based on Parent Caty
  const filteredSubcategories =
    category !== "all"
      ? categoriesData.find((cat) => cat.id === parseInt(category, 10))
          ?.sub_categories || []
      : [];


      //Search click event with the new params
  const handleSearch = () => {
      setMoreData(true)
    setPage(1)
    fetchData();
  };


  
  //Books Data 
  const [books, setBooks] = useState([]);
  //APIS ERROR
  const [error, setError] = useState(null);
  //Loading spinner
  const [loading, setLoading] = useState(false);

//Navigate if not Auth 
  let navigate = useNavigate();

  const getAllCategories = async () => {
    try {
      const response = await axios.get(
        "https://linker-pub.com/linkerapis/public/api/Categories",
        {
          headers: {
            Authorization: `Bearer ${Token}`,
            "Content-Type": "application/json",
            "x-api-key": "mwDA9w",
          },
        }
      );

      if (response.status === 200) {
        console.log(response.data.data);
        setCategoriesData(response.data.data);
      } else {
        console.error("Authentication failed:", response.status);
      }
    } catch (error) {
      console.error("Error Fetching Categorys:", error.message);
    }

    // console.log(responses.payload.data.data)
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const apiUrl = "https://linker-pub.com/linkerapis/public/api/Book/Search";

      const urlParams = new URLSearchParams();
      if (category !== "all") {
        urlParams.append("category", category);
      }
      if (subCategory !== "all") {
        urlParams.append("sub_category", subCategory);
      }
      if (searchTerm.trim() !== "") {
        urlParams.append("bookmark", searchTerm.trim());
      }

    //   if (searchTerm.trim() !== "") {
        urlParams.append("page", page);
    //   }

      const fullUrl = `${apiUrl}?${urlParams.toString()}`;

      const response = await axios.get(fullUrl, {
        headers: {
          Authorization: `Bearer ${Token}`,
          "Content-Type": "application/json",
          "x-api-key": "mwDA9w",
        },
      });
      const { success, data, message } = response.data;
      if (success) {
        console.log("URL" , fullUrl)
        console.log("Books=>" , response);
        if(response.data.data.length == 0){
            setMoreData(false)
        }
        if (page === 1) {
            // If it's the first page, replace existing data
            setBooks(response.data.data);
          } else {
            // If it's not the first page, append to existing data
            setBooks(prevBooks => [...prevBooks, ...response.data.data]);
          }
        
        setError(null); // Reset error state
        // console.log(books);
      } else {
        setError("يرجي تسجيل الدخول اولا");
      }
    } catch (error) {
      console.error("Error fetching books:", error);
      setError("يرجي تسجيل الدخول اولا");
    } finally {
      setLoading(false);
    }
  };


  const loadMore = ()=>{
    console.log(page)
    if(moreData){
        setPage(pevPage => pevPage +1 )
    }else{
        setPage(pevPage => pevPage -1 )
    }
  }

  useEffect(() => {
    localStorage.getItem("isAuthenticated");
    localStorage.getItem("Token");
    fetchData();
    getAllCategories();
    document.title = "لينكر | جميع الكتب";
  }, []);

  useEffect(() => {
    if(moreData){
        fetchData();
    }else{
        setNoMoreDataText('لا يوجد كتب أخري ')
    }
  }, [page]);



  //Get 30 Word from the description
  const truncateDescription = (description, wordLimit) => {
    if (description) {
      const words = description.split(" ");
      const truncatedWords = words.slice(0, wordLimit);
      return truncatedWords.join(" ") + (words.length > wordLimit ? "..." : "");
    } else {
      return "";
    }
  };

  return (
    <>
      {error ? (
        <div style={{ height: "90vh" }}>
          <div
            style={{ height: "100vh" }}
            className="d-flex flex-column justify-content-center align-items-center"
          >
            <div
              style={{ width: "600px", fontSize: "20px" }}
              className="alert alert-danger text-center mt-3 px-5 "
              role="alert"
            >
              {error}
            </div>
            {/* You can add additional error handling/display logic here */}
            <Link className="btn btn-danger" to="/login">
              {" "}
              تسجيل دخول{" "}
            </Link>
          </div>
        </div>
      ) : (
        <>
          <div className={styles.bg}>
            <Container>
              <Row className="mt-5 justify-content-center">
                <Col md={5}>
                  <input
                    type="text"
                    className="form-control"
                    id="search"
                    placeholder="كلمات مفتاحية"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </Col>
                <Col md={3}>
                  <select
                    className="form-select"
                    value={category}
                    onChange={(e) => {
                      setCategory(e.target.value);
                      // Reset subCategory when changing the category
                      setSubCategory("all");
                    }}
                  >
                    <option value="all"> التخصصات</option>
                    {categoriesData
                      ?.filter(
                        (categoriesData) =>
                          categoriesData.sub_categories.length !== 0
                      )
                      .map((categoriesData) => (
                        <option
                          key={categoriesData.id}
                          value={categoriesData.id}
                        >
                          {categoriesData.name}
                        </option>
                      ))}
                  </select>
                </Col>
                <Col md={3}>
                  <select
                    className="form-select"
                    value={subCategory}
                    onChange={(e) => setSubCategory(e.target.value)}
                  >
                    <option value="all">التخصصات الفرعية</option>
                    {filteredSubcategories.map((subcat) => (
                      <option key={subcat.id} value={subcat.id}>
                        {subcat.name}
                      </option>
                    ))}
                  </select>
                </Col>
                <Col className="text-center" md={1}>
                  <button
                    className={`btn ${styles.searchBtn}`}
                    onClick={handleSearch}
                  >
                    ابحث
                  </button>
                </Col>
              </Row>
            </Container>
          </div>
          {loading && (
            <BarLoader className="mt-2" color="#FF7C05" width="100%" />
          )}
          <Container>
            <Row className="mt-5 pt-5 justify-content-center">
                {/* filter the duplicated entries if fetched 2 times  */}
              {
                books
                .filter((book, index, self) => 
                  index === self.findIndex((b) => (
                    b.id === book.id
                  ))
                /* filter the duplicated entries if fetched 2 times  */
                ).map((book) => (
                  <div
                    key={book.id}
                    onClick={() => navigate(`/book/${book.id}`)}
                    style={{ width: "auto" }}
                  >
                    <div className={styles.mainNotbook}>
                      <h3>{truncateDescription(book.book_title, 6)}</h3>
                      <h6>{book.book_main_category}</h6>
                      <hr />
                      <p>{truncateDescription(book.book_description, 30)}</p>
                      <hr />
                      <h6>{book.user_info.full_name}</h6>
                    </div>
                  </div>
                ))
            }
            </Row>
          </Container>
          <div className="d-flex justify-content-center">
          <Button  className={`btn ${styles.searchBtn}`} onClick={loadMore}>المذيد</Button>

          </div>
          <h1 className="text-center">{noMoreDataText}</h1>
        </>
      )}
    </>
  );
};

export default Books;
