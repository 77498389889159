import { createSlice } from "@reduxjs/toolkit";

let TokenSlice =createSlice({
    name:"token",
    initialState:{
        Token:"",
        isAuthenticated:false,
        isAuthor:false,
        isPub:false,
        userId:0
    },
    reducers:{
        changeToken:(x,y)=>{
            x.Token=y.payload
        },
        changeisAuthenticated:(x,y)=>{
            x.isAuthenticated=y.payload
        },
        changeisisAuthor:(x,y)=>{
            x.isAuthor=y.payload
        },
        changeisisPub:(x,y)=>{
            x.isPub=y.payload
        },
        changeUserId:(x,y)=>{
            x.userId=y.payload
        },
    }
    
})
export let TokenReducer = TokenSlice.reducer;
export let {changeToken,changeisAuthenticated,changeisisAuthor,changeisisPub,changeUserId} = TokenSlice.actions;