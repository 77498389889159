import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import styles from "../../styles/form.module.css";
import "react-phone-input-2/lib/bootstrap.css";
import { useNavigate } from "react-router-dom";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import spinnerstyles from '../../styles/spinner.module.css'
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReCAPTCHA from "react-google-recaptcha";
import Select from 'react-select';

const AuthSignUp2 = () => {

    const [countries , setCountries] = useState([])

  useEffect(() => {
    // Fetch the countries from the JSON file
    fetch(process.env.PUBLIC_URL + "/countries-arabic.json")
      .then((response) => response.json())
      .then((data) => {
        // Sort countries in Arabic alphabetical order
        const sortedCountries = sortCountries(data);
        setCountries(sortedCountries);
      })
      .catch((error) => console.error("Error fetching countries:", error));
  }, []);

  const sortCountries = (countries) => {
    return countries.sort((a, b) => a.name.localeCompare(b.name, 'ar'));
  };


  function getCurrentDate() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    password_confirmation: "",
    birth_date: "",
    nationalty: "",
    living_home: "",
    job: "",
    education: "",
    cv: "",
    phone: "",
    whatapp_num: "",
    is_publish_same_contry: "",
    fav_contact: "",
    is_published_before: "",
    book_name: [""],
  });


  const [fieldValidity, setFieldValidity] = useState({
    name: true,
    email: true,
    password: true,
    password_confirmation:true,
    birth_date:true,
    nationalty:true,
    living_home:true,
    phone:true,
    whatapp_num:true,
    fav_contact:true,
    is_published_before:true,
  });
  

  const handleBookNameChange = (e, index) => {
    const { value } = e.target;
    setFormData((prevFormData) => {
      const updatedBookNames = [...prevFormData.book_name];
      updatedBookNames[index] = value;
      return {
        ...prevFormData,
        book_name: updatedBookNames,
      };
    });
  };

  const handleAddBook = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      book_name: [...prevFormData.book_name, ""],
    }));
  };


  useEffect(() => {
    document.title = "لينكر | حساب جديد";
  }, []);

  return (
    <>




      <ToastContainer />
      <h1 className={`mt-5 text-center ${styles.headerText}`}>سجل كمؤلف</h1>
      <Container className="mt-5">
        <form >
          <h4 className="mt-2 mb-4">معلومات تسجيل الدخول</h4>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="name">
                <Form.Label>الإسم <span style={{color:"#f00"}}> * </span></Form.Label>
                <Form.Control
                  name="name"
                  value={formData.name}
                  style={{ border: fieldValidity.name ? "" : "1px solid red" }}
                  type="text"
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="email">
                <Form.Label>البريد الإلكتروني <span style={{color:"#f00"}}> * </span></Form.Label>
                <Form.Control
                  name="email"
                  value={formData.email}
                  style={{ border: fieldValidity.name ? "" : "1px solid red" }}
                  type="email"
                />
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group className="mb-3" controlId="password">
                <Form.Label>كلمة المرور <span style={{color:"#f00"}}> * </span></Form.Label>
                <Form.Control
                  name="password"
                  value={formData.password}
                  type="password"
                  style={{ border: fieldValidity.name ? "" : "1px solid red" }}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="password_confirmation">
                <Form.Label>تأكيد كلمة المرور <span style={{color:"#f00"}}> * </span></Form.Label>
                <Form.Control
                  name="password_confirmation"
                  value={formData.password_confirmation}
                  type="password"
                  style={{ border: fieldValidity.name ? "" : "1px solid red" }}
                />
              </Form.Group>
            </Col>
          </Row>
          <hr className={styles.hr} />
          <h4 className="mt-2 mb-4">معلومات حول المؤلف</h4>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="birth_date">
                <Form.Label>تاريخ الميلاد <span style={{color:"#f00"}}> * </span></Form.Label>
                <Form.Control
                  name="birth_date"
                  value={formData.birthDate}
                  type="date"
                  max={getCurrentDate()}
                  style={{ border: fieldValidity.name ? "" : "1px solid red" }}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="nationalty">
                <Form.Label>الجنسية <span style={{color:"#f00"}}> * </span></Form.Label>
                <select
                  className="form-select"
                  name="nationalty"
                  style={{ border: fieldValidity.name ? "" : "1px solid red" }}
                  value={formData.nationalty}
                >
                  <option value="">اختر البلد</option>
                  {countries.map((country) => (
                    <option key={country.code} value={country.name}>
                      {country.name}
                    </option>
                  ))}
                </select>
              </Form.Group>

              <Form.Group className="mb-3" controlId="living_home">
                <Form.Label>بلد الإقامة <span style={{color:"#f00"}}> * </span></Form.Label>
                <select
                  className="form-select"
                  name="living_home"
                  style={{ border: fieldValidity.name ? "" : "1px solid red" }}
                >
                  <option value="">اختر البلد</option>
                  {countries.map((country) => (
                    <option key={country.code} value={country.name}>
                      {country.name}
                    </option>
                  ))}
                </select>
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group className="mb-3" controlId="job">
                <Form.Label>المهنة</Form.Label>
                <Form.Control
                  name="job"
                  value={formData.job}
                  type="text"

                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="education">
                <Form.Label>المؤهل الدراسي</Form.Label>
                <Form.Control
                  name="education"
                  value={formData.education}
                  type="text"

                />
              </Form.Group>

              <Form.Group controlId="cv" className="mb-3">
                <Form.Label>السيرة الذاتية إن وجد</Form.Label>
                <Form.Control
                  name="cv"
                  value={formData.cv}
                  type="file"
                  accept=".pdf, .doc, .docx"
                />
              </Form.Group>
            </Col>
          </Row>
          <hr className={styles.hr} />
          <h4 className="mt-2 mb-4">بيانات التواصل</h4>
          <Row>
            <Col md={12}>
              <Form.Group className="mb-3" controlId="phone">
                <Form.Label>رقم الهاتف <span style={{color:"#f00"}}> * </span></Form.Label>

                <PhoneInput
                  country={"us"}
                  inputClass="ms-5"
                  style={{ border: fieldValidity.name ? "" : "1px solid red" , width: "fit-content" }}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="wahtapp_num">
                <Form.Label>رقم واتساب <span style={{color:"#f00"}}> * </span></Form.Label>
                <PhoneInput
                  country={"us"}
                  inputClass="ms-5"
                  style={{ border: fieldValidity.name ? "" : "1px solid red" , width: "fit-content" }}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={12}>

            <Form.Group controlId="is_published_before" className="mb-3">
                <Form.Label>هل لديك مؤلفات تم نشرها من قبل؟ <span style={{color:"#f00"}}> * </span></Form.Label>
                <Form.Check
                  reverse
                  type="radio"
                  label="نعم"
                  value="yes"
                  name="is_published_before"
                  id="is_published_beforeYes"
                />
                <Form.Check
                  reverse
                  type="radio"
                  label="لا"
                  value="no"
                  name="is_published_before"
                  id="is_published_beforeNo"
                />
              </Form.Group>

              {formData.is_published_before === "yes" && (
                <div>
                  {formData.book_name?.map((bookName, index) => (
                    <Form.Group
                      key={index}
                      controlId={`book_name[${index}]`}
                      className="mb-3"
                    >
                      <Form.Label>إسم الكتاب {index + 1}</Form.Label>
                      <Form.Control
                        type="text"
                        value={bookName}
                        name={`book_name[${index}]`} // Add this line
                      />
                    </Form.Group>
                  ))}
                  <button
                    type="button"
                    onClick={handleAddBook}
                    className={`btn ${styles.btnPlus}`}
                  >
                    كتاب آخر
                  </button>
                </div>
              )}

              <Form.Group controlId="fav_contact" className="mb-3">
                <Form.Label>ما هي الطريقة الأفضل في التواصل؟ <span style={{color:"#f00"}}> * </span></Form.Label>
                <Form.Check
                  reverse
                  type="radio"
                  label="واتساب"
                  value="واتساب"
                  name="fav_contact"
                  id="fav_contactWhatssapp"
                />
                <Form.Check
                  reverse
                  type="radio"
                  label="هاتف"
                  value="هاتف"
                  name="fav_contact"
                  id="fav_contactMobile"
                />
                <Form.Check
                  reverse
                  type="radio"
                  label="البريد الإلكتروني"
                  value="البريد الإلكتروني"
                  name="fav_contact"
                  id="fav_contactEmail"
                />
              </Form.Group>


            </Col>
          </Row>


          <div className="text-center">
            <button type="submit" className={`btn ${styles.submitBtn}`}>
              حساب جديد
            </button>
          </div>
        </form>
      </Container>
    </>
  );
};

export default AuthSignUp2;
