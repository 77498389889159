import React, { useState } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import styles from "../../styles/home.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";


const NewPassword = () => {

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const Token = queryParams.get("token");

  const navigate = useNavigate();

  const [password, setPassword] = useState("");
  const [password_confirmation, setPassword_confirmation] = useState("");





  const handleSubmit = async (e) => {
    const data = {
      password,
      password_confirmation,
    };

    e.preventDefault();
    console.log(data)
    // Check if passwords match
    if (password !== password_confirmation) {
      toast.error("كلمة المرور غير مطابقة");
      return;
    }

    if(password.length < 6){
      toast.error(" يجب أن لا تقل كلمة المرور عن 6 أحرف أو أرقام ");
      return;
    }

    try {
      const response = await axios.post(
        "https://linker-pub.com/linkerapis/public/api/New-Password",
        data,
        {
          headers: {
            Authorization: `Bearer ${Token}`,
            "x-api-key": "mwDA9w",
          },
        }
      );

      if (response.data.success) {
        toast.success("تم تعيين كلمة المرور بنجاح");
        setTimeout(() => {
          navigate('/login');
        }, 3000);
      }
      console.log(response)

    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("حدث خطأ أثناء معالجة الطلب");
    }



}



  return (
    <div>

        <>
        <ToastContainer />
          <Container className="mt-5">
            <Row className="justify-content-center">
              <Col className="m-auto" md={{ span: 6, offset: 3 }}>
                <h2>تعيين كلمة مرور جديدة  </h2>
                <Form>
                  <Form.Group controlId="password">
                    <Form.Label>أدخل كلمة المرور </Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="أدخل كلمة المرور"
                      name="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                  </Form.Group>


                  <Form.Group controlId="password">
                    <Form.Label>أدخل كلمة المرور  مرة أخرى </Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="أدخل كلمة المرور  مرة أخرى"
                      name="password"
                      value={password_confirmation}
                      onChange={(e) => setPassword_confirmation(e.target.value)}
                      required
                    />
                  </Form.Group>

             
                  
                  <div className="mt-5 text-center">
                    <Button
                      onClick={handleSubmit}
                      className={styles.homeButtons}
                      variant="primary"
                      type="button"
                    >
                      تاكيد 
                    </Button>
                  </div>
                </Form>
              </Col>
            </Row>
          </Container>
        </>
    
    </div>
  );
};

export default NewPassword;
