import React, { useEffect } from "react";

//styles
import styles from "../../styles/signup.module.css";
import { Button, Col, Container, Row } from "react-bootstrap";

//Assets
import signAsAuth from "../../assets/imgs/signasAuth2.png";
import signAsPub from "../../assets/imgs/signasPub1.png";

import soonImages from "../../assets/imgs/soonImages.png";
import { Link } from "react-router-dom";
// import Footer from "../../layouts/footer";
import { IoCheckmarkSharp } from "react-icons/io5";
const SiginUpAs = () => {
  useEffect(() => {
    document.title = "لينكر | نوع الحساب ؟";
  }, []);

  return (
    <>
      <div className={styles.header}>
        <Container>
          <Row className={styles.cardsHeader}>
            <Col md={5} sm={12}>
              <div className="d-flex flex-column">
                <div>
                  <h1 className={styles.headerText}> أختر تخصصك و سجل الآن </h1>
                </div>

                <Row className={styles.cardsRow}>
                  <Col className={`text-center ${styles.mainCardcol}`}>
                    <div className={styles.signUpCart}>
                      <img className={styles.card} src={signAsAuth} alt="img" />
                      <div className={styles.cardContent}>
                        <h3> </h3>
                        <h4> التسجيل مجانًا لفترة محدودة </h4>

                        <hr className={styles.linkerHr} />
                        <div className={styles.cardContentStart}>
                          <h5> سجل بياناتك و أحصل علي : </h5>
                          <h6>
                            <IoCheckmarkSharp style={{ color: "green" }} />{" "}
                            إنشاء حساب خاص{" "}
                          </h6>
                          <h6>
                            <IoCheckmarkSharp style={{ color: "green" }} /> ظهور
                            بيانات عملك في قائمة بحث الناشرين{" "}
                          </h6>
                          <h6>
                            <IoCheckmarkSharp style={{ color: "green" }} />{" "}
                            ترشيح عملك علي أكبر عدد من الناشرين المختصين بالنشر
                            في مجال مؤلفك
                          </h6>
                          <h6>
                            <IoCheckmarkSharp style={{ color: "green" }} /> فرصة
                            أكبر لنشر مؤلفك{" "}
                          </h6>
                        </div>
                      </div>
                      <Link to="/author-signup">
                        <Button className={styles.button}>سجل الآن</Button>
                      </Link>
                    </div>
                  </Col>
                  <Col className={`text-center ${styles.mainCardcol}`}>
                    <div className={styles.signUpCart}>
                      <img className={styles.card} src={signAsPub} alt="img" />
                      <div className={styles.cardContent}>
                        <h3></h3>
                        <h4> التسجيل مجانًا لفترة محدودة </h4>

                        <hr className={styles.linkerHr} />
                        <div className={styles.cardContentStart}>
                          <h5>سجل بياناتك و أحصل علي : </h5>
                          <h6>
                            {" "}
                            <IoCheckmarkSharp style={{ color: "green" }} />{" "}
                            إنشاء حساب خاص{" "}
                          </h6>
                          <h6>
                            {" "}
                            <IoCheckmarkSharp style={{ color: "green" }} />{" "}
                            تحديد التخصص و البحث عن المحتوي المرغوب في نشره{" "}
                          </h6>
                          <h6>
                            <IoCheckmarkSharp style={{ color: "green" }} />{" "}
                            التواصل مع جميع صانعي المحتوي ومقدمي الخدمات في
                            صناعة النشر مباشرةً{" "}
                          </h6>
                        </div>
                      </div>
                      <Link to="/publisher-signup">
                        <Button className={styles.button}>سجل الآن</Button>
                      </Link>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={7}></Col>
          </Row>
        </Container>
      </div>

      <div className={styles.secondrySec}>
        <Container>
          <div className="text-center mt-5">
            <h1>خدمات أخرى قريبًا </h1>

            <img className="w-100" src={soonImages} alt="خدمات قريبا " />
          </div>
        </Container>
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default SiginUpAs;
