import React, { useState } from "react";

// Header Components
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

//Assets
import logo from "../assets/imgs/Linker_Logo.png";
import styles from "../styles//layouts/header.module.css";

//icons
import { FaFacebook } from "react-icons/fa";
import { SlSocialInstagram } from "react-icons/sl";
import { IoMailOpen } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";

export const Header = (  ) => {
const navigate = useNavigate()
  const logout = ()=>{
    localStorage.clear();
navigate('/')
window.location.reload();

  }

  const [expanded, setExpanded] = useState(false);

  const handleLinkClick = () => {
    setExpanded(false);
  };

  const isAuthenticated = localStorage.getItem("isAuthenticated") === "true";
  const isAuthor = localStorage.getItem("isAuthor") === "true";
  const isPub = localStorage.getItem("isPub") === "true";

   
  return (

    <>
    {/* <button onClick={()=>changeUserId(88888888888)}>Change</button>
    <h4 className="bg-danger fs-1">{isAuthenticated} {isAuthor}{isPub}</h4> */}
      <Navbar expand="lg"  expanded={expanded} className={`bg-body-tertiary ${styles.navMain}`}>
      {/* <Notification /> */}
        <Container>
          <Navbar.Brand href="#">
            <Link to='/'>
            <img style={{ width: "150px" }}  src={logo} alt="" />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" onClick={() => setExpanded(!expanded)} />
          <Navbar.Collapse className="navCollaps" id="navbarScroll">
            <Nav
              className="m-auto my-2 my-lg-0"
              style={{ maxHeight: "100px" }}
              navbarScroll
            >
              <Link to="/" onClick={handleLinkClick}> 
                <span className={`nav-link ${styles.navLink}`}>الرئيسية</span>
              </Link>
              <Link to="/about" onClick={handleLinkClick}>
                <span className={`nav-link ${styles.navLink}`} href="#action1">
                  من نحن
                </span>
              </Link>

              {isPub && (
              <Link to="/lookingfor" onClick={handleLinkClick}>
                  <span
                    className={`nav-link ${styles.navLink}`}
                    href="#action1"
                  >
                    تبحث عن
                  </span>
                </Link>
)}

                {!isAuthenticated && (
              <Link to="/create-account" onClick={handleLinkClick}>
              <span className={`nav-link ${styles.navLink}`} href="#action1">
                حساب جديد
              </span>
            </Link>
              )}
                

                {!isAuthenticated && (
              <Link to="/login" onClick={handleLinkClick}>
              <span className={`nav-link ${styles.navLink}`} href="#action1">
                تسجيل دخول
              </span>
            </Link>
              )}



              
{isAuthor && (
                <Link to="/author-profile" onClick={handleLinkClick}>
                  <span
                    className={`nav-link ${styles.navLink}`}
                    href="#action1"
                  >
                    حسابي
                  </span>
                </Link>

)}


{isPub && (


                <Link to="/publisher-profile" onClick={handleLinkClick}>
                  <span
                    className={`nav-link ${styles.navLink}`}
                    href="#action1"
                  >
                    حسابي
                  </span>
                </Link>

)}



{isAuthor && (
      <Link to="/add-new" onClick={handleLinkClick}>
        <span className={`nav-link ${styles.navLink}`}>
          أضف كتاب
        </span>
      </Link>
)}

{isAuthenticated && (

              <span style={{cursor:'pointer'}}  onClick={logout} >
              <span className={`nav-link ${styles.navLink}`} href="#action1">
                تسجيل خروج
              </span>
            </span>
)}


<Link to="/contact" onClick={handleLinkClick}>
        <span className={`nav-link ${styles.navLink}`}>
          تواصل معنا
        </span>
      </Link>





            </Nav>
            <div className={`d-flex justify-content-center`}>
              <Link to="/contact" className={styles.socialIcons}>
                <IoMailOpen />
              </Link>
              <a href="" className={styles.socialIcons}>
                <SlSocialInstagram />
              </a>
              <a href="https://www.facebook.com/profile.php?id=61555813523463&mibextid=LQQJ4d" target="_blank" className={styles.socialIcons}>
                <FaFacebook />
              </a>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};
