import React, { useEffect, useState } from "react";
import { Button, Container, Modal, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { ToastContainer, toast } from "react-toastify";
import { CgProfile } from "react-icons/cg";
import { FaBook } from "react-icons/fa";
import { CiEdit } from "react-icons/ci";

//Assets
import styles from "../../styles/profile.module.css";

import { Link, Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import Footer from "../../layouts/footer";
import { BarLoader } from "react-spinners";
import UpdatePubProfile from "./updatePub";

const PubProfile = () => {
  const isPub = localStorage.getItem("isPub");
  const Token = localStorage.getItem("Token");

  const [value, setValue] = useState(0);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  //Modal
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [userData, setUserData] = useState({
    id: "",
    full_name: "",
    phone: "",
    email: "",
    last_login: "",
    created_at: "",
    user_publisher: {
      id: "",
      local_pub_num: "",
      arab_pub_num: "",
      num_publihsed_books: "",
      certificates: null,
      web_url: "",
      facebook_url: "",
      wahtapp_num: "",
      country: "",
      cat_fav: [],
      number_titles_published_secondary: "",
      pub_name: "",
      publish_doctoral_masters: "",
      care_translation: "",
      published_translated_books: "",
      count_post: "",
      interested_granting_translation: "",
      sold_translation_rights_to_any_of_your_publications: "",
      count_sold_translation: "",
      platform_nominate_books_offered_publication_favorite_specialty: "",
      branches_in_other_countries: "",
    },
    country_branch: [],
  });
  let navigate = useNavigate();
  const fetchData = async () => {
    try {
      setLoading(true);

      const response = await axios.get(
        "https://linker-pub.com/linkerapis/public/api/Profile-Publisher",
        {
          headers: {
            Authorization: `Bearer ${Token}`,
            "x-api-key": "mwDA9w",
          },
        }
      );

      const { success, data, message } = response.data;

      // Log the data to the console for inspection
      console.log("API Response:", data);

      setUserData(data);
    } catch (error) {
      setError(error);
      console.error("Error fetching User Data:", error);
    } finally {
      setLoading(false);
      console.log("Fetched successfully");
    }
  };
  const handelDeleteAccount = async (e) => {
    e.preventDefault();
    const response = await axios.post(
      "https://linker-pub.com/linkerapis/public/api/Delete-Account",
      null,
      {
        headers: {
          Authorization: `Bearer ${Token}`,
          "x-api-key": "mwDA9w",
          "Content-Language": "ar",
          "Content-Type": "application/json",
        },
      }
    );
    console.log(response, Token);
    if (response.data.success) {
      localStorage.clear();
      navigate("/");
      toast(response.data.message);
    }

    // toast(response.data.message);
  };
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    console.log("userData:", userData);
  }, [userData]);

  const truncateDescription = (description, wordLimit) => {
    if (description) {
      const words = description.split(" ");
      const truncatedWords = words.slice(0, wordLimit);
      return truncatedWords.join(" ") + (words.length > wordLimit ? "..." : "");
    } else {
      return ""; // or handle it in another way based on your requirements
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  useEffect(() => {
    document.title = "لينكر | حسابي";
  }, []);

  return (
    <>
      {error ? (
        <div style={{ height: "90vh" }}>
          <div
            style={{ height: "100vh" }}
            className="d-flex flex-column justify-content-center align-items-center"
          >
            <div
              style={{ width: "600px", fontSize: "20px" }}
              className="alert alert-danger text-center mt-3 px-5 "
              role="alert"
            >
              {error}
            </div>
            {/* You can add additional error handling/display logic here */}
            <Link className="btn btn-danger" to="/login">
              {" "}
              تسجيل دخول{" "}
            </Link>
          </div>
        </div>
      ) : (
        <>
          {loading && (
            <BarLoader className="mt-2" color="#FF7C05" width="100%" />
          )}
          {/* <h1>{userData.full_name}</h1> */}

          <Container className="mt-5">
            <div className="d-flex justify-content-center">
              <h1>{userData.user_publisher.pub_name}</h1>
            </div>

            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <div className={styles.tabs}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    variant="scrollable"
                    scrollButtons="auto"
                  >
                    <Tab
                      className={styles.tab}
                      label={
                        <div className="d-flex">
                          <CgProfile />
                          <h5 className={styles.tabText}>
                            {" بيانات دار النشر "}
                          </h5>
                        </div>
                      }
                      {...a11yProps(0)}
                    />

                    <Tab
                      className={styles.tab}
                      label={
                        <div className="d-flex">
                          <FaBook />
                          <h5 className={styles.tabText}>
                            {" التخصصات و الفروع "}
                          </h5>
                        </div>
                      }
                      {...a11yProps(1)}
                    />

                    <Tab
                      className={styles.tab}
                      label={
                        <div className="d-flex">
                          <CiEdit />
                          <h5 className={styles.tabText}>{" تعديل الحساب "}</h5>
                        </div>
                      }
                      {...a11yProps(2)}
                    />
                  </Tabs>
                </div>
              </Box>
              <CustomTabPanel value={value} index={0}>
                <div className={styles.authData}>
                  <div className={styles.hrHalf}></div>
                  <div className={styles.hrtext}>بيانات الدار</div>
                  <div className={styles.hrHalf}></div>
                </div>

                <div className={styles.data}>
                  <div
                    className={` justify-content-center ${styles.datafilds}`}
                  >
                    <div style={{ width: "74%" }} className={styles.dataFild}>
                      <h4>إسم دار النشر </h4>
                      <hr />
                      <h5>{userData.user_publisher.pub_name}</h5>
                    </div>

                    
                  </div>

                  <div className={styles.datafilds}>
                    <div className={styles.dataFild}>
                      <h4>رقم اتحاد الناشرين المحلي </h4>
                      <hr />
                      <h5>{userData.user_publisher.local_pub_num}</h5>
                    </div>

                    

                    <div className={styles.dataFild}>
                      <h4>رقم اتحاد الناشرين العرب</h4>
                      <hr />
                      <h5>{userData.user_publisher.arab_pub_num}</h5>
                    </div>
                  </div>

                  <div className={styles.datafilds}>
                    <div className={styles.dataFild}>
                      <h4>عدد الإصدارات</h4>
                      <hr />
                      <h5>{userData.user_publisher.num_publihsed_books}</h5>
                    </div>

                    <div className={styles.dataFild}>
                      <h4>جوايز الدار</h4>
                      <hr />
                      <h5>
                        <a href={userData.user_publisher.certificates}>
                          {" "}
                          جوايز الدار{" "}
                        </a>
                      </h5>
                    </div>
                  </div>

                  <div className={styles.datafilds}>
                  <div style={{ width: "75%" }} className={styles.dataFild}>
                      <h4>البلد</h4>
                      <hr />
                      <h5>
                      <h5>{userData.user_publisher.country}</h5>
                      </h5>
                    </div>
                  </div>

                  <div className={styles.authData}>
                    <div className={styles.hrHalf}></div>
                    <div className={styles.hrtext}>عن الدار</div>
                    <div className={styles.hrHalf}></div>
                  </div>
                </div>

                <div className={styles.data}>
                  <div className={styles.datafildsRow}>
                    <div className={styles.dataFildRow}>
                      <h4>عدد الاصدارات السنوية </h4>
                      <hr />
                      <h5>
                        {
                          userData.user_publisher
                            .number_titles_published_secondary
                        }
                      </h5>
                    </div>
                  </div>

                  <div className={styles.datafildsRow}>
                    <div className={styles.dataFildRow}>
                      <h4>هل تهتم بالترجمة </h4>
                      <hr />
                      <h5>
                        {userData.user_publisher.care_translation == "yes"
                          ? "نعم"
                          : userData.user_publisher.care_translation === "no"
                          ? "لا"
                          : ""}
                      </h5>
                    </div>
                  </div>

                  <div className={styles.datafildsRow}>
                    <div className={styles.dataFildRow}>
                      <h4>هل نشرت كتب مترجمة </h4>
                      <hr />
                      <h5>
                        {userData.user_publisher.published_translated_books ==
                        "yes"
                          ? "نعم"
                          : userData.user_publisher
                              .published_translated_books === "no"
                          ? "لا"
                          : ""}
                      </h5>
                    </div>
                  </div>

                  <div className={styles.datafildsRow}>
                    <div className={styles.dataFildRow}>
                      <h4>عدد الكتب المترجمة التي تم نشرها </h4>
                      <hr />
                      <h5>{userData.user_publisher.count_post}</h5>
                    </div>
                  </div>

                  <div className={styles.datafildsRow}>
                    <div className={styles.dataFildRow}>
                      <h4>هل تهتم بمنح الترجمة </h4>
                      <hr />
                      <h5>
                        {userData.user_publisher
                          .interested_granting_translation == "yes"
                          ? "نعم"
                          : userData.user_publisher
                              .interested_granting_translation === "no"
                          ? "لا"
                          : ""}
                      </h5>
                    </div>
                  </div>

                  <div className={styles.datafildsRow}>
                    <div className={styles.dataFildRow}>
                      <h4>هل تم بيع حقوق ترجمة </h4>
                      <hr />
                      <h5>
                        {userData.user_publisher
                          .sold_translation_rights_to_any_of_your_publications ==
                        "yes"
                          ? "نعم"
                          : userData.user_publisher
                              .sold_translation_rights_to_any_of_your_publications ===
                            "no"
                          ? "لا"
                          : ""}
                      </h5>
                    </div>
                  </div>

                  <div className={styles.datafildsRow}>
                    <div className={styles.dataFildRow}>
                      <h4>عدد الحقوق التي تم بيعها </h4>
                      <hr />
                      <h5>{userData.user_publisher.count_sold_translation}</h5>
                    </div>
                  </div>

                  <div className={styles.datafildsRow}>
                    <div className={styles.dataFildRow}>
                      <h4>
                        هل تريد من المنصة أن ترشح لك الكتب المعروضة للنشر الخاصة
                        بتخصصاتك المفضلة{" "}
                      </h4>
                      <hr />
                      <h5>
                        {userData.user_publisher
                          .platform_nominate_books_offered_publication_favorite_specialty ==
                        "yes"
                          ? "نعم"
                          : userData.user_publisher
                              .platform_nominate_books_offered_publication_favorite_specialty ===
                            "no"
                          ? "لا"
                          : ""}
                      </h5>
                    </div>
                  </div>

                  <div className={styles.datafildsRow}>
                    <div className={styles.dataFildRow}>
                      <h4>هل لديك فروع أخري خارج البلاد </h4>
                      <hr />
                      <h5>
                        {userData.user_publisher.branches_in_other_countries ==
                        "yes"
                          ? "نعم"
                          : userData.user_publisher
                              .branches_in_other_countries === "no"
                          ? "لا"
                          : ""}
                      </h5>
                    </div>
                  </div>

                  <div className={styles.authData}>
                    <div className={styles.hrHalf}></div>
                    <div className={styles.hrtext}>بيانات التواصل</div>
                    <div className={styles.hrHalf}></div>
                  </div>

                  <div className={styles.datafildsRow}>
                    <div className={styles.dataFildRow}>
                      <h4>رقم WhatsApp</h4>
                      <hr />
                      <h5>{userData.user_publisher.wahtapp_num}</h5>
                    </div>
                  </div>

                  <div className={styles.datafildsRow}>
                    <div className={styles.dataFildRow}>
                      <h4>رقم الهاتف</h4>
                      <hr />
                      <h5>{userData.phone}</h5>
                    </div>
                  </div>

                  <div className={styles.datafildsRow}>
                    <div className={styles.dataFildRow}>
                      <h4> البريد الإلكتروني </h4>
                      <hr />
                      <h5>{userData.email}</h5>
                    </div>
                  </div>

                  <div className={styles.datafildsRow}>
                    <div className={styles.dataFildRow}>
                      <h4> الموقع الإلكتروتي </h4>
                      <hr />
                      <h5>{userData.user_publisher.web_url}</h5>
                    </div>
                  </div>

                  <div className={styles.datafildsRow}>
                    <div className={styles.dataFildRow}>
                      <h4> الفيسبوك </h4>
                      <hr />
                      <h5>{userData.user_publisher.facebook_url}</h5>
                    </div>
                  </div>
                </div>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <Row className="mt-5 pt-5 justify-content-center">
                  <h1> التخصصات المفضلة </h1>

                  {userData.user_publisher.cat_fav.map((item, index) => (
                    <div key={index}>
                      <h4>
                        {item.name || (item.category && item.category.name)}
                      </h4>
                      {/* Additional properties like sub_categories can be accessed similarly */}
                      {item.sub_categories && (
                        <ul>
                          {item.sub_categories.map((subCategory, subIndex) => (
                            <li key={subIndex}>{subCategory}</li>
                          ))}
                        </ul>
                      )}
                      <hr />
                    </div>
                  ))}

                  <h1 className="mt-5"> فروع الدار </h1>

                  {userData.country_branch.length > 0 ? (
                    userData.country_branch.map((branch) => (
                      <div key={branch.id}>
                        <h4>{branch.country_name}</h4>
                        <hr />
                      </div>
                    ))
                  ) : (
                    <p>لا يوجد فروع اخري</p>
                  )}
                </Row>
              </CustomTabPanel>

              <CustomTabPanel value={value} index={2}>
                <Row className="mt-5 pt-5 justify-content-center">
                  <UpdatePubProfile
                    fullName={userData.full_name}
                    email={userData.email}
                    phone={userData.phone}
                  />
                  <Button
                    style={{ width: "300px" }}
                    className="btn btn-danger"
                    onClick={handleShow}
                  >
                    {" "}
                    حذف الحساب{" "}
                  </Button>

                  <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                      <Modal.Title>حذف الحساب</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>يرجي تاكيد حذف الحساب </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleClose}>
                        رجوع
                      </Button>
                      <Button
                        variant="danger"
                        onClick={() => {
                          handleClose();
                          handelDeleteAccount();
                        }}
                      >
                        تاكيد
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </Row>
              </CustomTabPanel>
            </Box>
          </Container>
        </>
      )}
    </>
  );
};

export default PubProfile;
