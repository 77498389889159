import React, { useEffect, useState } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import styles from "../../styles/home.module.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import spinnerstyles from '../../styles/spinner.module.css'


const AddNewBook = () => {

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  //*  Check if user Author */
  const [shouldRender, setShouldRender] = useState(true);


  /*Post Api Vars */
  const [bookName, setBookName] = useState("");
  const [pageNumbers, setpageNumbers] = useState("");
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [selectedSubcategoryId, setSelectedSubcategoryId] = useState("");
  const [description, setDescription] = useState("");

  /*Handle Caty and sub caty */
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");





  const fetchData = async () => {

    try {
        const res = await axios.get('https://linker-pub.com/linkerapis/public/api/Categories' , {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": "mwDA9w",
          },
        });
        if (res.status === 200) {
          console.log(res);
          setCategories(res.data.data)
        } else {
          console.error('Authentication failed:', res.data.message);
          console.log(res);
  
        }
      } catch (error) {
        console.error('Error Fetching Categorys:', error.message);
      }
    };

    
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    document.title = "لينكر | كتاب جديد";
  }, []);

  /*
   * Handle The Change of option Values when User select a caty it shows only the related sub caty
   */
  let changeit = (e) => setSelectedCategoryId(e.target.value);
  const handleCategoryChange = (e) => {
    const selectedCategoryId = e.target.value;
    // console.log("sss", e.target.value)
    // console.log(selectedCategoryId); // Add this line
    setSelectedCategory(e.target.value);
    changeit(e);

    // Filter subcategories based on the selected category
    const selectedCategoryData = categories.find(
      (category) => category.id === parseInt(selectedCategoryId, 10)
    );
    setSubcategories(
      selectedCategoryData ? selectedCategoryData.sub_categories : []
    );
  };


  const Token = localStorage.getItem("Token");
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (!description.trim()) {
      // Display an error message or handle the empty description case
      toast.error("يرجي ادخال نبذة عن الكتاب ");
      return; // Don't proceed with the API call
    }

    const bookdata = {
      book_title: bookName,
      book_category_id: selectedCategoryId,
      book_description: description,
      sub_cat: selectedSubcategoryId,
      book_number: pageNumbers,
      token: Token,
    };

    try{
      setLoading(true);

      const res = await axios.post('https://linker-pub.com/linkerapis/public/api/Book/add' , bookdata ,{
        headers: {
          Authorization: `Bearer ${Token}`,
          "Content-Type": "application/json",
          "x-api-key": "mwDA9w",
        },
      });  
      if(res.data.success){

        toast.success("تم اضافة الكتاب بنجاح انتظر العروض");

        setTimeout(() => {
          navigate('/author-profile')
        }, 3000);
      }else{
        toast.error("يوجد خطاْ يرجي اعادة المحاولة");
      }

    }catch(error){
      if(error.response.data.message == "Please Enter Select Sub Category"){
        toast.error("يرجي احتيار التخصص الفرعي ");
      }else if(error.response.data.message == "Please Enter Your book title"){
        toast.error("يرجي كتابة عنوان الكتاب  ");

      }else if(error.response.data.message == "Please Enter Select Main Category"){
        toast.error("يرجي اختيار التخصص   ");

      }

      console.log(error)
    }finally{
      setLoading(false);

    }
    
  };

  return (
    <div>
      {loading && (
      <div className={spinnerstyles.overlay}>
      <div className={spinnerstyles.spinner}></div>
      </div>
      )}
      <ToastContainer />
      {shouldRender ? (
        <>
          <Container className="mt-5">
            <Row className="justify-content-center">
              <Col className="m-auto" md={{ span: 6, offset: 3 }}>
                <h2>اضف كتاب جديد</h2>
                <Form>
                  <Form.Group controlId="bookName">
                    <Form.Label>عنوان الكتاب</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="اضف عنوان الكتاب"
                      name="bookName"
                      value={bookName}
                      onChange={(e) => setBookName(e.target.value)}
                      required
                    />
                  </Form.Group>

                  <Form.Group controlId="category">
                    <Form.Label>التخصص</Form.Label>
                    <Form.Control
                      as="select"
                      name="category"
                      value={selectedCategoryId}
                      onChange={handleCategoryChange}
                      onBlur={(e) => setSelectedCategoryId(e.target.value)}
                      required
                    >
                      <option value="" defaultValue>
                        اختر التخصص
                      </option>
                      {categories
                        ?.filter(
                          (category) => category.sub_categories.length !== 0
                        )
                        .map((category) => (
                          <option key={category.id} value={category.id}>
                            {category.name}
                          </option>
                        ))}
                    </Form.Control>
                  </Form.Group>

                  <Form.Group controlId="subcategory">
                    <Form.Label>التخصص الفرعي</Form.Label>
                    <Form.Control
                      as="select"
                      name="subcategory"
                      value={selectedSubcategoryId}
                      onChange={(e) => setSelectedSubcategoryId(e.target.value)}
                      required
                    >
                      <option value="">اختر التخصص الفرعي</option>
                      {subcategories.map((subcategory) => (
                        <option key={subcategory.id} value={subcategory.id}>
                          {subcategory.name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>

                  <Form.Group controlId="bookName">
                    <Form.Label> عدد الصفحات</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="اضف عدد الصفحات"
                      name="bookName"
                      value={pageNumbers}
                      onChange={(e) => setpageNumbers(e.target.value)}
                      required
                    />
                  </Form.Group>

                  <Form.Group controlId="description">
                    <Form.Label>نبذة عن الكتاب</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={4}
                      placeholder="اضف نبذة قصيرة عن الكتاب"
                      name="description"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      required
                    />
                  </Form.Group>
                  <div className="mt-5 text-center">
                    <Button
                      onClick={handleFormSubmit}
                      className={styles.homeButtons}
                      variant="primary"
                      type="submit"
                    >
                      نشر
                    </Button>
                  </div>
                </Form>
              </Col>
            </Row>
          </Container>
        </>
      ) : (
        <h1>You Are Not Allowd </h1>
      )}
    </div>
  );
};

export default AddNewBook;
