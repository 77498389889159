import React, { useEffect, useState } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import styles from "../../styles/home.module.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
const ForgetPassword = () => {

  const navigate = useNavigate();
  const [email, setemail] = useState("");


  const handelSubmit =  async (e) => {
    e.preventDefault();
    const response = await axios.post(
         "https://linker-pub.com/linkerapis/public/api/Forget-Password", { email },
         {
           headers: {
             "x-api-key": "mwDA9w",
           },
         }
       );

       if(response.data.success){
 
         toast.success("  تم إرسال إيميل تعيين كلمة المرور  بنجاح   ");
         toast.success("  (spam)-  إذا لم يظهر لك أنظر في ال   ");

         setTimeout(() => {
           navigate('/')
         }, 6000);
       }else if(response.data.message == "This E-Mail Not Correct"){
        toast.error(" البريد الإلكتروني غير صحيح ");
       }
         console.log(response);
  };
 



  return (
    <div>
        <>
    <ToastContainer />

          <Container className="mt-5">
            <Row className="justify-content-center">
              <Col className="m-auto" md={{ span: 6, offset: 3 }}>
              <h2>تعيين كلمه مرور جديدة  </h2>
                              <Form>
                  <Form.Group controlId="email">
                    <Form.Label>أدخل البريد الإلكتروني </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="اضف البريد الالكتروني"
                      name="email"
                      value={email}
                      onChange={(e) => setemail(e.target.value)}
                      required
                    />
                  </Form.Group>

                




                  
                  <div className="mt-5 text-center">
                    <Button
                      className={styles.homeButtons}
                      variant="primary"
                      type="button"
                      onClick={handelSubmit}
                    >
                      ارسال 
                    </Button>
                  </div>
                </Form>
              </Col>
            </Row>
          </Container>
        </>
    
    </div>
  );
};

export default ForgetPassword;
