import React, { useEffect } from "react";

import styles from "../../styles/home.module.css";
import { Button, Col, Container, Row } from "react-bootstrap";

//Assets
import linkerSec2 from "../../assets/imgs/Linkersec2.png";
// import { TypeAnimation } from "react-type-animation";
import auth from "../../assets/imgs/auth.png";
import pub from "../../assets/imgs/pub.png";

import htrans from "../../assets/imgs/htrans.png";
import hmodakk from "../../assets/imgs/hmoddkk.png";
import hdesigner from "../../assets/imgs/hdesigner.png";
import hmonask from "../../assets/imgs/hmonask.png";
// import { Header } from "../layouts/header";
// import Footer from "../layouts/footer";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const Home = () => {

  useEffect(() => {
    document.title = "لينكر | الرئيسية";
  }, []);

  return (
    <>
      <>
        <div className={styles.sec1}>
          <Container>
            <div className={styles.sec1Div}>
              {/* <img className={styles.imagePuzzel} src={imagePuzzel} alt="" /> */}
            </div>

            <div className={`${styles.sec1Div} mt-5 mb-5`}>
              <motion.div
                variants={{
                  hidden: { opacity: 0, y: -100 },
                  visible: { opacity: 1, y: 0 },
                }}
                initial="hidden"
                whileInView="visible"
                transition={{ duration: 0.5, ease: "easeInOut" }}
              >
                <h3 className={styles.sec1Text}>
                  {" "}
                  أول منصة تربط كافة خدمات صناعة النشر في الشرق الأوسط{" "}
                </h3>
              </motion.div>
            </div>

            <div className={styles.sec1Div}>
              <motion.div
                variants={{
                  hidden: { opacity: 0, y: 100 },
                  visible: { opacity: 1, y: 0 },
                }}
                initial="hidden"
                whileInView="visible"
                transition={{ duration: 0.5, ease: "easeInOut" }}
              >
                <Link to='/about'>
                <Button className={styles.btnMore}>المزيد</Button>
                
                </Link>
              </motion.div>
            </div>
          </Container>
        </div>
        <div className={styles.resSec2}>
          <div className={styles.sec2}>
            <Container>
              <Row className={styles.TabletView}>
                <Col
                  md={7}
                  className={`d-flex justify-content-center flex-column mt-3`}
                >
                  <motion.div
                    variants={{
                      hidden: { opacity: 0, x: 100 },
                      visible: { opacity: 1, x: 0 },
                    }}
                    initial="hidden"
                    whileInView="visible"
                    transition={{ duration: 0.3, delay: 0.3 }}
                  >
                    <h1 className={styles.textHead}>مرحبًا بك في لينكر</h1>
                  </motion.div>
                  <motion.div
                    variants={{
                      hidden: { opacity: 0, x: 100 },
                      visible: { opacity: 1, x: 0 },
                    }}
                    initial="hidden"
                    whileInView="visible"
                    transition={{ duration: 0.3, delay: 0.6 }}
                  >
                    <p className={styles.homeP}>
                      لينكر هي أول منصة في الشرق الأوسط تعمل على ربط كافة خدمات
                      النشر في مكانٍ واحد، وذلك من خلال إتاحة الفرصة أمام
                      المؤلفين لعرض نبذات أعمالهم المراد نشرها على المنصة؛ لكي
                      يتسنى لأكبر عدد من الناشرين الوصول إليهم واختيار المناسب
                      من مؤلفاتهم  بناءً على اهتمامات كل دار نشر وتخصصاتها
                    </p>

                    <p className={styles.homeP}>
                      كما تتيح أيضًا المنصة للناشرين فرصة الوصول للمحتوى المطلوب
                      نشره بكل سهولة ويسر، وذلك من خلال التصفح ما بين العديد من
                      عناوين الأعمال المعروضة ونبذاتها في مختلف المجالات
                      والتواصل مع المؤلف مباشرةً.
                    </p>
                    <p className={`${styles.homeP} font-weight-bold`}>
                      لن يقتصر نشاط المنصة على المؤلف والناشر فقط، بل ستوفر
                      المنصة أيضًا عددً كبيرً من المصممين، المدققين اللغوين،
                      المنسقين، المحررين الأدبيين والمترجمين في مختلف اللغات
                      الذين يريدون عرض خدماتهم؛ وذلك لكي تتمكن أي دار نشر من
                      الحصول على الخدمات التي تنقصها بشكل عملي واحترافي.
                    </p>

                    <p className={`${styles.homeP} font-weight-bold`}>
                      <span style={{fontWeight:"bold"}}>
                      نحرص في منصة لينكر بشكل أساسي على أهمية الحفاظ على حقوق
                      الملكية الفكرية لكل صناع المحتوى؛ لذلك لن يتم إتاحة عرض أي
                      محتوى كاملًا علي المنصة، فقط نتيح عرض بيانات العمل
                      (العنوان المبدئي - عدد الصفحات التقريبي - التخصص - نبذة
                      جذابة ومختصرة عنه) وذلك ليتسنى للناشر اختيار العمل المناسب
                      له والتواصل مع مؤلفه.
                      </span>
                    </p>
                  </motion.div>
                </Col>

                <Col className="d-flex justify-content-center" md={5}>
                  <motion.div
                    variants={{
                      hidden: { opacity: 0, x: -100 },
                      visible: { opacity: 1, x: 0 },
                    }}
                    initial="hidden"
                    whileInView="visible"
                    transition={{ duration: 0.6, delay: 0.3 }}
                  >
                    <img className={styles.image2} src={linkerSec2} alt="" />
                  </motion.div>
                </Col>
              </Row>
            </Container>
          </div>
          <Container>
            <Row className={styles.TabletView}>
              <Col
                className={`${styles.authResHide} d-flex justify-content-center`}
                md={6}
              >
                <motion.div
                  variants={{
                    hidden: { opacity: 0, x: 100 },
                    visible: { opacity: 1, x: 0 },
                  }}
                  initial="hidden"
                  whileInView="visible"
                  transition={{ duration: 0.6, delay: 0.3 }}
                >
                  <img className={styles.image2} src={auth} alt="" />
                </motion.div>
              </Col>
              <Col md={6} className="d-flex justify-content-center flex-column">
                <motion.div
                  variants={{
                    hidden: { opacity: 0, x: -100 },
                    visible: { opacity: 1, x: 0 },
                  }}
                  initial="hidden"
                  whileInView="visible"
                  transition={{ duration: 0.6, delay: 0.5 }}
                >
                  <h1 className={styles.textHead}>مؤلف</h1>
                </motion.div>

                <motion.div
                  variants={{
                    hidden: { opacity: 0, x: -100 },
                    visible: { opacity: 1, x: 0 },
                  }}
                  initial="hidden"
                  whileInView="visible"
                  transition={{ duration: 0.6, delay: 0.9 }}
                >
                  <p className={styles.homeP}>
                    نسعى لخلق علاقة مترابطة بين دور النشر والمؤلفين؛ وذلك من خلال
                     إتاحة فرصة أكبر لظهور المحتوى الواحد لأكبر عدد من
                    الناشرين المهتمين بنشر هذا النوع من المحتوى. حيث يظهر كل عمل
                    داخل التخصص الخاص به، مما يسهل على الناشرين عملية البحث
                    وإيجاد المحتوى المطلوب بشكل سهل واحترافي. وإيمانًا منا
                    بدورنا في تطوير صناعة النشر، سنحرص على ترشيح عملكم لأكبر عدد
                    من الناشرين المهتمين بتخصص مؤلفكم.
                    <br />
                    <span
                      style={{
                        fontWeight: "800",
                      }}
                    >
                      كل ما عليك فعله هو إضافة بيانات الكتاب: العنوان - عدد
                      الصفحات - التخصص - نبذة جذابة ومختصرة عنه، ليتمكن الناشر
                      من اختيار عملك و التواصل معك.
                    </span>
                  </p>
                </motion.div>
                <div className="text-center">
                <Link to="/author-signup">
                  <Button className={styles.homeButtons}>
                    {" "}
 سجل الاَن كمؤلف 
                  </Button>
                  </Link>
                </div>
              </Col>

              <Col
                className={`${styles.authResShow} d-flex justify-content-center`}
                md={6}
              >
                <motion.div
                  variants={{
                    hidden: { opacity: 0, x: 100 },
                    visible: { opacity: 1, x: 0 },
                  }}
                  initial="hidden"
                  whileInView="visible"
                  transition={{ duration: 0.6, delay: 0.3 }}
                >
                  <img className={styles.image2} src={auth} alt="" />
                </motion.div>
              </Col>
            </Row>

            <Row className={styles.TabletView}>
              <Col md={6} className="d-flex justify-content-center flex-column">
                <motion.div
                  variants={{
                    hidden: { opacity: 0, x: 100 },
                    visible: { opacity: 1, x: 0 },
                  }}
                  initial="hidden"
                  whileInView="visible"
                  transition={{ duration: 0.6, delay: 0.3 }}
                >
                  <h1 className={styles.textHead}>ناشر</h1>
                </motion.div>

                <motion.div
                  variants={{
                    hidden: { opacity: 0, x: 100 },
                    visible: { opacity: 1, x: 0 },
                  }}
                  initial="hidden"
                  whileInView="visible"
                  transition={{ duration: 0.6, delay: 0.5 }}
                >
                  <p className={styles.homeP}>
                    يواجه أغلب الناشرين صعوبة في إيجاد المؤلفات التي تتماشى مع
                    إهتماماتهم وسياسات نشرهم؛ حيث يتقدم لهم آلاف الأعمال في كافة
                    التخصصات، والتي قد تتسبب أحيانًا في اختفاء عامل تكافؤ الفرص
                    في اختيار الأعمال.
                  </p>
                </motion.div>

                <motion.div
                  variants={{
                    hidden: { opacity: 0, x: 100 },
                    visible: { opacity: 1, x: 0 },
                  }}
                  initial="hidden"
                  whileInView="visible"
                  transition={{ duration: 0.6, delay: 0.7 }}
                >
                  <p className={styles.homeP}>
                وهنا يأتي دور منصة لينكر، حيث أنها تتيح الفرصة للناشرين في
                    تحديد التخصص والبحث عن المحتوى المرغوب فيه في مكانٍ واحد
                    كما سيتمكن الناشر من التواصل مع المؤلف صاحب العمل المختار
                    بشكل مباشر.
                  </p>

                  <p className={styles.homeP}>
كما ستتيح له المنصة قريبًا التواصل مع جميع مقدمي الخدمات المخلتفة في صناعة النشر .
                  </p>
                </motion.div>
                <div className="text-center">
                <Link to="/publisher-signup"> 
                  <Button className={styles.homeButtons}>
                    {" "}
سجل الاَن كناشر 
                  </Button>
                  </Link>
                </div>
              </Col>

              <Col className="d-flex justify-content-center" md={6}>
                <img className={styles.image2} src={pub} alt="" />
              </Col>
            </Row>

            <Row className="mt-5 flex-wrap">
              <Col md={12}>
                <h1 className="text-center"> خدمات سيتم توفيرها قريبًا </h1>
              </Col>

              <Col className="d-flex justify-content-center flex-wrap mt-5 align-items-center text-center">
                <motion.div
                  variants={{
                    hidden: { opacity: 0, y: 100 },
                    visible: { opacity: 1, y: 0 },
                  }}
                  initial="hidden"
                  whileInView="visible"
                  transition={{ duration: 0.5, ease: "easeInOut" }}
                >
                  <img className={styles.soonImages} src={htrans} alt="" />
                </motion.div>
                <motion.div
                  variants={{
                    hidden: { opacity: 0, y: 100 },
                    visible: { opacity: 1, y: 0 },
                  }}
                  initial="hidden"
                  whileInView="visible"
                  transition={{ duration: 0.7, ease: "easeInOut" }}
                >
                  <img className={styles.soonImages} src={hdesigner} alt="" />
                </motion.div>
                <motion.div
                  variants={{
                    hidden: { opacity: 0, y: 100 },
                    visible: { opacity: 1, y: 0 },
                  }}
                  initial="hidden"
                  whileInView="visible"
                  transition={{ duration: 0.9, ease: "easeInOut" }}
                >
                  <img className={styles.soonImages} src={hmodakk} alt="" />
                </motion.div>
                <motion.div
                  variants={{
                    hidden: { opacity: 0, y: 100 },
                    visible: { opacity: 1, y: 0 },
                  }}
                  initial="hidden"
                  whileInView="visible"
                  transition={{ duration: 0.9, ease: "easeInOut" }}
                >
                  <img className={styles.soonImages} src={hmonask} alt="" />
                </motion.div>
              </Col>
            </Row>
          </Container>
          {/* <Footer /> */}
        </div>
      </>
    </>
  );
};

export default Home;
