import React from "react";
import styles from "../styles/layouts/footer.module.css";
import { Link } from "react-router-dom";

//Assets
import siteLogo from "../assets/imgs/icon.png";

import { FaFacebook } from "react-icons/fa";
import { SlSocialInstagram } from "react-icons/sl";
import { IoMailOpen } from "react-icons/io5";
const Footer = () => {
  return (
    <>
      <footer className={styles["footer-16371"]}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-9 text-center">
              <div className={styles["footer-site-logo"]}>
                <Link to='/'>
                <img src={siteLogo} alt="site_logo" />
                </Link>
              </div>
              <ul className={`list-unstyled ${styles["nav-links"]} mb-5`}>
                <li>
                  <Link to="/">الرئيسية</Link>
                </li>
                <li>
                  <Link to="/about">من نحن</Link>
                </li>
                <li>
                  <Link to="/create-account">حساب جديد</Link>
                </li>
                <li>
                  <Link to="/login">تسجيل دخول</Link>
                </li>
                <li>
                  <Link to="/contact"> تواصل معنا </Link>
                </li>
              </ul>
              <div className={styles.social}>
                <h3>صفحات التواصل الاجتماعي</h3>
                <div className="">
                  <Link to="/contact" className={styles.socialIcons}>
                    <IoMailOpen />
                  </Link>
                  <a href="" className={styles.socialIcons}>
                    <SlSocialInstagram />
                  </a>
                  <a
                    href="https://www.facebook.com/profile.php?id=61555813523463&mibextid=LQQJ4d"
                    target="_blank"
                    className={styles.socialIcons}
                  >
                    <FaFacebook />
                  </a>
                </div>
              </div>

              <div className={styles.copyright}>
                <p className="mb-0">
                  <small>&copy; Linker. All Rights Reserved.</small>
                </p>
                <p className="mb-0">
                  <small>
                    Powered by <a href="https://astrotechsol.com">ASTROTECH</a>{" "}
                  </small>
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
