import React, { useEffect } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";

import styles from "../../styles/lookfor.module.css";

//Assets
import lookModakik from "../../assets/imgs/lookModdakik.png";
import lookAuthor from "../../assets/imgs/lookAuthor.png";
import lookDesigner from "../../assets/imgs/lookDesigner.png";
import lookMonasik from "../../assets/imgs/lookMonasik.png";
import lookTranslate from "../../assets/imgs/lookTrans.png";

import lookModakikMob from "../../assets/imgs/mobile/lookModakik.png";
import lookAuthorMob from "../../assets/imgs/mobile/lookAuth.png";
import lookDesignerMob from "../../assets/imgs/mobile/lookdesigner.png";
import lookMonasikMob from "../../assets/imgs/mobile/lookoMonasik.png";
import lookTranslateMob from "../../assets/imgs/mobile/looktranslate.png";


import { Link } from "react-router-dom";

const LookingFor = () => {
  useEffect(() => {
    document.title = "لينكر | تبحث عن ؟";
  }, []);
  return (
    <>
      <div className={styles.mainBack}>
        <Container className={styles.webDesign}>
          <Row className="mt-5">
            <Col md={12} className="mb-5">
              <img  alt="img" src={lookAuthor} className={styles.image} />
              <div className="text-center">
                <Link to="/books">
                  <Button className={styles.button}> مؤلف </Button>
                </Link>
              </div>
            </Col>
          </Row>

          <Row
            className={`${styles.CommingSoon} justify-content-center align-items-center`}
          >
            <Col md={3} className="text-center">
              <img alt="img"  src={lookModakik} className={styles.imageGray} />
              <div className="text-center">
                <Button className={styles.buttonGray}> مدقق لغوي</Button>
              </div>
            </Col>

            <Col md={3} className="text-center">
              <img alt="img"  src={lookDesigner} className={styles.imageGray} />
              <div className="text-center">
                <Button className={styles.buttonGray}> مُصمم </Button>
              </div>
            </Col>

            <Col md={3} className="text-center">
              <img  alt="img" src={lookTranslate} className={styles.imageGray} />
              <div className="text-center">
                <Button className={styles.buttonGray}> مُترجم </Button>
              </div>
            </Col>

            <Col md={3} className="text-center">
              <img  alt="img" src={lookMonasik} className={styles.imageGray} />
              <div className="text-center">
                <Button className={styles.buttonGray}> مُنسق </Button>
              </div>
            </Col>
          </Row>
        </Container>

    <div className={styles.test}></div>
        <Container className={styles.mobileDesign}>
          <Row className="mt-5">
            <Col md={12} className="mb-5">
              <img  alt="img" src={lookAuthorMob} className={styles.image} />
              <div className="text-center">
                <Link to="/books">
                  <Button className={styles.button}> مؤلف </Button>
                </Link>
              </div>
            </Col>
          </Row>

          <Row
            className={`${styles.CommingSoon} justify-content-center align-items-center flex-column`}
          >
            <Col md={12} sm={12} className="text-center">
              <img  alt="img" src={lookModakikMob} className={styles.imageGray} />
              <div className="text-center">
                <Button className={styles.buttonGray}> مدقق لغوي </Button>
              </div>
            </Col>

            <Col md={12} sm={12} className="text-center">
              <img  alt="img" src={lookDesignerMob} className={styles.imageGray} />
              <div className="text-center">
                <Button className={styles.buttonGray}> مُصمم </Button>
              </div>
            </Col>

            <Col md={12} sm={12} className="text-center">
              <img  alt="img"  src={lookTranslateMob} className={styles.imageGray} />
              <div className="text-center">
                <Button className={styles.buttonGray}> مُترجم </Button>
              </div>
            </Col>

            <Col md={12} sm={12} className="text-center">
              <img  alt="img" src={lookMonasikMob} className={styles.imageGray} />
              <div className="text-center">
                <Button className={styles.buttonGray}> مُنسق </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default LookingFor;
