import React, { useEffect, useState } from 'react';
import styles from "../../styles/login.module.css";
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext.js';
import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ReCAPTCHA from "react-google-recaptcha";

import spinnerstyles from '../../styles/spinner.module.css'



const Login = ({setData}) => {

  const [loading, setLoading] = useState(false);


  //------------------Google ReCAPTCHA -----------------------------//
  const [isNotRobot, setIsNotRobot] = useState(false);
  const recaptchaKey = '6Lf9EVIpAAAAAKYXsDiCOGtM2VCv5bG7zfrkmZZ_'

  const handleRecaptchaChange = (value) => {
    // This callback will be triggered when the user solves the reCAPTCHA.
    setIsNotRobot(true);
  };
  //------------------Google ReCAPTCHA -----------------------------//


  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [errorMessage, setErrorMessage] = useState("");

  const { login } = useAuth();
  const navigate = useNavigate();


  const handleInputChange = (e) => {
    let { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };


  const handleLogin = async () => {
    if(isNotRobot){    
    try {
      setLoading(true);

        const response = await axios.post('https://linker-pub.com/linkerapis/public/api/login', formData , {
            headers: {
              "Content-Type": "application/json",
              "x-api-key": "mwDA9w",
            },
          });
          if (response.status === 200) {
        setErrorMessage('') 
          login(response.data.token);
          
          console.log(response.data.message)

          if(!response.data.success){
            if(response.data.message == 'this password not correct'){
              toast.error('كلمة المرور غير صحيحة ')
            }else if(response.data.message == 'this E-Mail not correct'){
              toast.error('البريد الإليكتروني غير صحيح')
            }else if(response.data.message == 'Your Account Not Active Check Your E-Mail To Active Your Account'){
              toast.error('الحساب غير مفعل يرجي تفعيل الحساب اولا ')
            }
          }

          if(response.data.success){
            if (response.data.data.user_auther) {
              localStorage.setItem('isAuthor', 'true');
              localStorage.setItem('isPub', 'false');
              localStorage.setItem('UserId',response.data.data.id );
              localStorage.setItem('Token',response.data.data.access_token );
  
            } else if (response.data.data.user_publisher) {
              localStorage.setItem('isPub', 'true');
              localStorage.setItem('isAuthor', 'false');
              localStorage.setItem('UserId',response.data.data.id );
              localStorage.setItem('Token',response.data.data.access_token );
  
            }
            toast.success(' تم تسجيل الدخول بنجاح ')


            setTimeout(() => {
              navigate('/'); 
              window.location.reload();
            }, 2000);
          
          }

          

          

        }
        console.log(response)
      } catch (error) {
        console.error('Error during login:', error.message);
        if(error.message == 'Request failed with status code 401'){
          toast.error(' من فضلك ادخل البريد الاليكتروني و كلمة المرور')
        }
      }finally{
        setLoading(false);

      }
    }else{
      toast.error('يرجى تأكيد أنك لست روبوت ')
    }
    };

    useEffect(() => {
      document.title = "لينكر | تسجيل دخول";
    }, []);
  

  return (
    <div className={styles.mainLoginPage}>
              {loading && (
        <div className={spinnerstyles.overlay}>
        <div className={spinnerstyles.spinner}></div>
        </div>
        )}
           <ToastContainer />
      <div className={styles.loginHeader}></div>
      <div className={styles.cardContainer}>
        <div className={styles.loginCard2}>
          <h1 className='text-center mb-5'>تسجيل دخول</h1>
          <form action="#!">
            {errorMessage && (
              <div className="alert alert-danger mt-3" role="alert">
                {errorMessage}
              </div>
            )}
            <div className="form-group">
              <label htmlFor="email" className="mb-1">
                البريد الإلكتروني{" "}
              </label>
              <input
                type="email"
                name="email"
                id="email"
                className={`form-control ${styles.formControl}`}
                  value={formData.email }
                  onChange={handleInputChange}
              />
            </div>
            <div className="form-group mb-4">
              <label htmlFor="password" className="mb-1">
                كلمة المرور
              </label>
              <input
                type="password"
                name="password"
                id="password"
                className={`form-control ${styles.formControl}`}
                value={formData.password }
                  onChange={handleInputChange}
              />
               <p className={` text-end ${styles.loginCardFooterText}`}>

          </p>
            </div>

            <ReCAPTCHA
            hl="ar" 
        sitekey={recaptchaKey}
        onChange={handleRecaptchaChange}
      />


            <div className='text-center'>
              <input
                onClick={handleLogin}
                className={`btn btn-block  mb-4 ${styles.loginBtn}`}
                type="button"
                value=" تسجيل الدخول"
              />
            </div>
          </form>

          <p className={` text-center ${styles.loginCardFooterText}`}>
            هل نسيت كلمة المرور ؟{" "}
            <Link to="/forget-password" className={`${styles.textReset}`}>
              انشاء كلمة مرور جديدة{" "}
            </Link>
          </p>

          <p className={` text-center ${styles.loginCardFooterText}`}>
            ليس لديك حساب ؟{" "}
            <Link to="/create-account" className={`${styles.textReset}`}>
              سجل حساب جديد الاَن{" "}
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
// ForgetPassword

}

export default Login;
