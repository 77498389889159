import React, { useEffect, useLayoutEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Container } from "react-bootstrap";
import axios from "axios";

import styles from "../../styles/profile.module.css"
import { ToastContainer, toast } from 'react-toastify';
import style from '../../styles/singleBook.module.css'


  
const SingleBook = ({}) => {
  const { bookId } = useParams();
  const Token = localStorage.getItem("Token");
  const isPub = localStorage.getItem("isPub") == 'true' ;


  const [book, setBook] = useState(null); 
  
  const UserId = localStorage.getItem("UserId");

  const [isWriteIt, setIsWriteIt] = useState(false);
          
  const [showCommunicationData, setShowCommunicationData] = useState(false);

  const handleShowCommunicationDataForAuthor = ()=>{
    setShowCommunicationData(!showCommunicationData);
  }
  const handleShowCommunicationData = async () => {
    setShowCommunicationData(!showCommunicationData);

    try {
      const response = await axios.get(`https://linker-pub.com/linkerapis/public/api/Book/Show-Author/${bookId}`, {
        headers: {
          Authorization: `Bearer ${Token}`,
          "Content-Type": "application/json",
          "x-api-key": "mwDA9w",
        },
      });
  
      if (response.status === 200) {
        console.log(response.data.data);
      } else {
        console.error("Authentication failed:", response.status);
      }
    } catch (error) {
      console.error("Error Fetching Categorys:", error.message);
    }
  

  };

    
const fetchData = async () => {
  try {
    const response = await axios.get(`https://linker-pub.com/linkerapis/public/api/Book/Book-Details/${bookId}`, {
      headers: {
        Authorization: `Bearer ${Token}`,
        "Content-Type": "application/json",
        "x-api-key": "mwDA9w",
      },
    });

    if (response.status === 200) {
      console.log(response.data.data);
      setBook(response.data.data)
      if(response.data.data.user_info.id == UserId){
        setIsWriteIt(true)
      }
    } else {
      console.error("Authentication failed:", response.status);
    }
  } catch (error) {
    console.error("Error Fetching Categorys:", error.message);
  }

  
  };
  const handelDeleteBook= async (e) => {
    e.preventDefault();
      const response = await axios.post(
        `https://linker-pub.com/linkerapis/public/api/Book/delete/${bookId}`,null,
        {
          headers: {
            Authorization: `Bearer ${Token}`,
            "x-api-key":"mwDA9w",
            "Content-Language":"ar",
            "Content-Type":"application/json"
            
          },
        }
      );
        console.log(response);
        if(response.data.success){
            navigate('/books')
            toast(response.data.message);
        }
      
        // toast(response.data.message);
        
     

      
    }
  let navigate= useNavigate()
    useEffect(() => {
     
      fetchData();
    },[]);
  
    console.log(isWriteIt)

    

    useEffect(() => {
      document.title = ` لينكر |  كتاب` ;
    }, []);

  if (!book) {
    return <div>Loading...</div>;
  }

  console.log('cdgsyhk')
  return (
    <>
     <ToastContainer />
    <Container>
    <div className="container  ">
    <div className="row d-flex justify-content-between align-items-end">
    <div className="col-md-1"></div>
    <div className="col-md-12"><div className={`mt-5 ${style.bookData}`}>
      <h2>{book.book_title}</h2>
      <p> <b>التصنيف :</b>  {book.book_main_category}</p>
      <p> <b>التصنيف الفرعي :</b> {book.book_sub_category}</p>
      <p className="text-dark"><b>المؤلف :</b>  {book?.user_info.full_name ||"" }</p>
      <p><b> نبذة عن الكتاب :</b> {book.book_description}</p>
      <p><b>  عدد الصفحات التقريبي :</b>  {book.book_number}</p>
      {/* Add more details as needed */}
    </div></div>
    <div className="col-md-3 ms-auto d-flex justify-content-evenly">
    
        
        </div>
    </div>
    </div>
    
    

      <>
      {!isWriteIt ? (
<>
    <div className={styles.authData}>
              <div className={styles.hrHalf}></div>
              <div className={styles.hrtext}>بيانات المؤلف</div>
              <div className={styles.hrHalf}></div>
            </div>

            <div className={styles.data}>
              <div className={styles.datafilds}>
                <div className={styles.dataFild}>
                  <h4>الاسم </h4>
                  <hr />
                  <h5>{book.user_info.full_name || ""}</h5>
                </div>
              </div>

              <div className={styles.datafilds}>
                <div className={styles.dataFild}>
                  <h4>المهنة</h4>
                  <hr />
                  <h5>{book.user_info.user_auther.job}</h5>
                </div>

                <div className={styles.dataFild}>
                  <h4>تاريخ الميلاد</h4>
                  <hr />
                  <h5>{book.user_info.user_auther.birth_date}</h5>
                </div>
              </div>

              <div className={styles.datafilds}>
                <div className={styles.dataFild}>
                  <h4>الجنسية</h4>
                  <hr />
                  <h5>{book.user_info.user_auther.nationalty}</h5>
                </div>

                <div className={styles.dataFild}>
                  <h4>بلد الاقامة</h4>
                  <hr />
                  <h5>{book.user_info.user_auther.living_home}</h5>
                </div>
              </div>

              <div className={styles.datafilds}>
                <div className={styles.dataFild}>
                  <h4>السيرة الذاتية</h4>
                  <hr />
                  <h5><a href={book.user_info.user_auther.cv}> السيرة الذاتية </a> </h5>
                </div>

                <div className={styles.dataFild}>
                  <h4>الموهل الدراسي</h4>
                  <hr />
                  <h5>{book.user_info.user_auther.education}</h5>
                </div>
              </div>


              <div className={styles.authData}>
              <div className={styles.hrHalf}></div>
              <div className={styles.hrtext}>عن المؤلف</div>
              <div className={styles.hrHalf}></div>
            </div>
            </div>

            <div className={styles.data}>



                <div className={styles.datafildsRow}>
                <div className={styles.dataFildRow}>
                  <h4>الطريقة الافضل للتواصل بالمؤلف</h4>
                  <hr />
                  <h5>
                    {book.user_info.user_auther.fav_contact}

</h5>
                </div>

                </div>

                <div className={styles.datafildsRow}>
                <div className={styles.dataFildRow}>
                  <h4>المؤلف لديه كتب نشرت سابقًا</h4>
                  <hr />
                  <h5>
                    
                    {book.user_info.user_auther.is_published_before === 'yes' ? (
          'نعم'
        ) : (
          // Display something else or leave it empty
          'لا'
        )}

                      <ul className={styles.UL}>
                        {book.user_info.user_author_old_books.map((book) => (
                          <li key={book.id}>{book.book_name}</li>
                        ))}
                                          </ul>
                    </h5>
                </div>

                </div>


                <div className={styles.authData}>
              <div className={styles.hrHalf}></div>
              <div className={styles.hrtext}>بيانات التواصل</div>
              <div className={styles.hrHalf}></div>
            </div>

            {isPub ? (

<div className={style.btnContainer}> 
            <button className={`btn  ${style.btnShow}`} onClick={handleShowCommunicationData}>
          {showCommunicationData ? "" : "إظهار "} بيانات التواصل
        </button>
        </div>
        ) : (
          <div className={style.btnContainer}> 
          <button className={`btn ${style.btnShow}`} onClick={handleShowCommunicationDataForAuthor}>
          {showCommunicationData ? "" : "إظهار "} بيانات التواصل
        </button>
        </div>
      )}
 {showCommunicationData && (
          <div>
             <div className={styles.datafildsRow}>
                <div className={styles.dataFildRow}>
                  <h4>رقم WhatsApp</h4>
                  <hr />
                  <h5>{book.user_info.user_auther.whatapp_num}</h5>
                </div>

                </div>

                <div className={styles.datafildsRow}>
                <div className={styles.dataFildRow}>
                  <h4>رقم الهاتف</h4>
                  <hr />
                  <h5>{book.user_info.phone}</h5>
                </div>

                </div>

                <div className={styles.datafildsRow}>
                <div className={styles.dataFildRow}>
                  <h4> البريد الإلكتروني اخر</h4>
                  <hr />
                  <h5>{book.user_info.email}</h5>
                </div>

                </div>
          </div>
        )}
           
                </div>
                </>
              ) : (
               <div className=" ms-3 row justify-content-between">
               <div className="col-6 text-center">
                    <Button
                       onClick={() =>
              navigate(
                `/update_Book/${book.book_title}/${book.id}/${book.book_description}/${book.book_sub_category}/${book.book_number}/${book.book_main_category}`
              )
            }
                      className="btn btn-primary"
                      variant="primary"
                      type="submit"
                    >
                      تعديل الكتاب
                    </Button>
                  </div>
                  <div className="col-6 text-center">
                    <Button
                      onClick={handelDeleteBook}
                      className="btn btn-danger"
                      variant="primary"
                      type="submit"
                    >
                      حذف الكتاب
                    </Button>
                  </div>
               </div>
              )}  
                </>
    </Container>
    </>
  );
};

export default SingleBook;
