import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import styles from "../../styles/form.module.css";
import "react-phone-input-2/lib/bootstrap.css";
import { useNavigate } from "react-router-dom";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import spinnerstyles from '../../styles/spinner.module.css'
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReCAPTCHA from "react-google-recaptcha";
import Select from 'react-select';

const AuthSignUp = () => {

  const [loading, setLoading] = useState(false);
  const formContainerRef = useRef();

  //------------------Google ReCAPTCHA -----------------------------//
  const [isNotRobot, setIsNotRobot] = useState(false);
  const recaptchaKey = '6Lf9EVIpAAAAAKYXsDiCOGtM2VCv5bG7zfrkmZZ_'

  const handleRecaptchaChange = (value) => {
    // This callback will be triggered when the user solves the reCAPTCHA.
    setIsNotRobot(true);
  };
  //------------------Google ReCAPTCHA -----------------------------//




  const Token = localStorage.getItem("Token");
  //Country Select
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [phone, setPhone] = useState("");

  useEffect(() => {
    // Fetch the countries from the JSON file
    fetch(process.env.PUBLIC_URL + "/countries-arabic.json")
      .then((response) => response.json())
      .then((data) => {
        // Sort countries in Arabic alphabetical order
        const sortedCountries = sortCountries(data);
        setCountries(sortedCountries);
      })
      .catch((error) => console.error("Error fetching countries:", error));
  }, []);

  const sortCountries = (countries) => {
    return countries.sort((a, b) => a.name.localeCompare(b.name, 'ar'));
  };

  const handleCountryChange = (e) => {
    setSelectedCountry(e.target.value);
  };

  function getCurrentDate() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    password_confirmation: "",
    birth_date: "",
    nationalty: "",
    living_home: "",
    job: "",
    education: "",
    cv: "",
    phone: "",
    whatapp_num: "",
    is_publish_same_contry: "",
    fav_contact: "",
    is_published_before: "",
    book_name: [""],
    user_type_id: '',

  });


  const [fieldValidity, setFieldValidity] = useState({
    name: true,
    email: true,
    password: true,
    password_confirmation:true,
    birth_date:true,
    nationalty:true,
    living_home:true,
    phone:true,
    whatapp_num:true,
    fav_contact:true,
    is_published_before:true,
  });
  

  let navigate = useNavigate();
  const [valuePhone, setValuevaluePhone] = useState('');
  const [valueWahtsApp, setvalueWahtsApp] = useState('');

  const [theFile, setTheFile] = useState(null);

  const handleInputChange = (e) => {
    let { name, value, type, files } = e.target;

//File Handle
if (type === "file" && files.length > 0) {
  setTheFile(files[0]); 
  formData.cv = theFile 
  console.log("file" ,theFile)
}





setFieldValidity((prevValidity) => ({
  ...prevValidity,
  [name]: value.trim() !== "",
}));




//File Handle

    setFieldValidity((prevValidity) => ({
      ...prevValidity,
      [name]: value.trim() !== "",
    }));


    if (name === "phone") {
      setValuevaluePhone(value);
    } else if (name === "whatsapp_num") {
      setvalueWahtsApp(value);
    } else {
      // For other form fields
      setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    }



    const phoneNumberWithPlus = valuePhone;
    const phoneNumberWithoutPlus = phoneNumberWithPlus.replace("+", "00");
    const WahtsAppWithPlus = valueWahtsApp;
    const WahtsAppWithoutPlus = WahtsAppWithPlus.replace("+", "00");

    formData.phone = phoneNumberWithoutPlus;
    formData.whatapp_num = WahtsAppWithoutPlus;
    setFormData({ ...formData, [name]: value });
  };


//******* */



  const handleBookNameChange = (index, value) => {
    const newBookNames = [...formData.book_name];
    newBookNames[index] = value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      book_name: newBookNames,
    }));
  };



  const handleAddBook = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      book_name: [...prevFormData.book_name, ""],
    }));
  };



  const handleSubmit = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;


    const requiredFields = ["name", "email", "password", "password_confirmation", "birth_date", "nationalty", "living_home", "phone", "whatapp_num", "fav_contact", "is_published_before"];

    let isValid = true;
  
    requiredFields.forEach((field) => {
      if (formData[field].trim() === "") {
        isValid = false;
        setFieldValidity((prevValidity) => ({
          ...prevValidity,
          [field]: false,
        }));
      }
    });

    
  
    // If any required field is invalid, stop the submission
    if (!isValid) {
      toast.error("يرجى ملء جميع الحقول المطلوبة بشكل صحيح");
      // Highlight all required fields with red border
      setFieldValidity((prevValidity) => ({
        ...prevValidity,
        ...Object.fromEntries(requiredFields.map((field) => [field, false])),
      }));

      window.scrollTo({
        top: 0,
        behavior: "smooth", 
      });
      return;
    }




    // console.log("Form data submitted:", formData);
    
    const bookNameObject = formData.book_name?.reduce((acc, value, index) => {
      acc[`book_name[${index}]`] = value;
      return acc;
    }, {});
    
    const formDataWithIndexedBookNames = { ...formData, ...bookNameObject };
    const apiKey = "mwDA9w";
    formDataWithIndexedBookNames.is_publish_same_contry = "yes";
    formDataWithIndexedBookNames.phone = valuePhone.replace("+", "00");
    formDataWithIndexedBookNames.whatapp_num = valueWahtsApp.replace("+", "00");

    if(formDataWithIndexedBookNames.phone.length < 4){
      formDataWithIndexedBookNames.phone = '';
      console.log(formDataWithIndexedBookNames.phone)
    }
    
    if(formDataWithIndexedBookNames.whatapp_num.length < 4){
      formDataWithIndexedBookNames.whatapp_num = ''
      console.log(formDataWithIndexedBookNames.whatapp_num)
    }

    if (formDataWithIndexedBookNames.email.indexOf('@') !== -1 &&
    formDataWithIndexedBookNames.email.split('@')[1].indexOf('.') === -1) {
  toast.error("البريد الإلكتروني غير صالح");
  return; // Stop the submission
}
formDataWithIndexedBookNames.cv = theFile;
formDataWithIndexedBookNames.user_type_id = 3



// Append file


    if(isNotRobot){    
      try {
      setLoading(true);
      const response = await axios.post(
        "https://linker-pub.com/linkerapis/public/api/register_auther",
        formDataWithIndexedBookNames,
        {
          headers: {
            "x-api-key": apiKey,
            'Content-Type': 'multipart/form-data'
  
          },
        }
      );

      console.log("Signup successful:", response.data.success);
      if(response.data.success){
        toast.success(" تم إنشاء الحساب بنجاح يمكنك تسجيل الدخول   ");
        setTimeout(() => {
          navigate("/login");
        }, 2500);
      }else{
        toast.error(" يوجد خطأ يرجي إعادة المحاولة");

      }
    } catch (error) {
      toast.error(error);
      console.log("Error signing up:", error);
      if (error.response.data.message == "This Phone Already Used Before") {
        toast.error("هذا الرقم مستخدم من قبل");
      } else if (
        error.response.data.message == "This E-Mail Already Used Before"
      ) {
        toast.error("البريد الالكتروني مستخدم من قبل");
      } else if (
        error.response.data.message ==
        "Password & Its Confirmation Not Matching"
      ) {
        toast.error("كلمة المرور غير متطابقة  ");
      } else if (
        error.response.data.message ==
        "please cheked is published before or no"
      ) {
        toast.error("هل لديك مؤلفات نُشرت من قبل مطلوب  ");
      }else if (
        error.response.data.message ==
        "Password Must Be At Least 6 Characters"
      ) {
        toast.error("يجب أن لا تقل كلمة المرور عن 6 أحرف أو أرقام  ");
      }else if (
        error.response.data.message ==
        "Please Enter Your phone"
      ) {
        toast.error(" أدخل رقم الهاتف  ");
      }
    }finally{
      setLoading(false);
    }
  }else{
      toast.error('يرجى تأكيد أنك لست روبوت ')
    }
    console.log("Form data submitted:", formDataWithIndexedBookNames);

  };

  useEffect(() => {
    document.title = "لينكر | حساب جديد";
  }, []);


  return (
    <>


{loading && (
  <div className={spinnerstyles.overlay}>
    <div className={spinnerstyles.spinner}></div>
  </div>
)}

      <ToastContainer />
      <h1 className={`mt-5 text-center ${styles.headerText}`}>سجل كمؤلف</h1>
      <Container className="mt-5">
        <form onSubmit={handleSubmit}>
          <h4 className="mt-2 mb-4">معلومات تسجيل الدخول</h4>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="name">
                <Form.Label>الإسم <span style={{color:"#f00"}}> * </span></Form.Label>
                <Form.Control
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  style={{ border: fieldValidity.name ? "" : "1px solid red" }}
                  type="text"
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="email">
                <Form.Label>البريد الإلكتروني <span style={{color:"#f00"}}> * </span></Form.Label>
                <Form.Control
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  style={{ border: fieldValidity.name ? "" : "1px solid red" }}
                  type="email"
                />
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group className="mb-3" controlId="password">
                <Form.Label>كلمة المرور <span style={{color:"#f00"}}> * </span></Form.Label>
                <Form.Control
                  name="password"
                  value={formData.password}
                  onChange={handleInputChange}
                  type="password"
                  style={{ border: fieldValidity.name ? "" : "1px solid red" }}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="password_confirmation">
                <Form.Label>تأكيد كلمة المرور <span style={{color:"#f00"}}> * </span></Form.Label>
                <Form.Control
                  name="password_confirmation"
                  value={formData.password_confirmation}
                  onChange={handleInputChange}
                  type="password"
                  style={{ border: fieldValidity.name ? "" : "1px solid red" }}
                />
              </Form.Group>
            </Col>
          </Row>
          <hr className={styles.hr} />
          <h4 className="mt-2 mb-4">معلومات حول المؤلف</h4>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="birth_date">
                <Form.Label>تاريخ الميلاد <span style={{color:"#f00"}}> * </span></Form.Label>
                <Form.Control
                  name="birth_date"
                  value={formData.birthDate}
                  onChange={handleInputChange}
                  type="date"
                  max={getCurrentDate()}
                  style={{ border: fieldValidity.name ? "" : "1px solid red" }}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="nationalty">
                <Form.Label>الجنسية <span style={{color:"#f00"}}> * </span></Form.Label>
                <select
                  className="form-select"
                  name="nationalty"
                  style={{ border: fieldValidity.name ? "" : "1px solid red" }}
                  value={formData.nationalty}
                  onChange={handleInputChange}
                >
                  <option value="">اختر البلد</option>
                  {countries.map((country) => (
                    <option key={country.code} value={country.name}>
                      {country.name}
                    </option>
                  ))}
                </select>
              </Form.Group>

              <Form.Group className="mb-3" controlId="living_home">
                <Form.Label>بلد الإقامة <span style={{color:"#f00"}}> * </span></Form.Label>
                <select
                  className="form-select"
                  name="living_home"
                  style={{ border: fieldValidity.name ? "" : "1px solid red" }}
                  value={selectedCountry}
                  onChange={(e) => {
                    handleInputChange(e);
                    handleCountryChange(e);
                  }}
                >
                  <option value="">اختر البلد</option>
                  {countries.map((country) => (
                    <option key={country.code} value={country.name}>
                      {country.name}
                    </option>
                  ))}
                </select>
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group className="mb-3" controlId="job">
                <Form.Label>المهنة</Form.Label>
                <Form.Control
                  name="job"
                  value={formData.job}
                  onChange={handleInputChange}
                  type="text"

                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="education">
                <Form.Label>المؤهل الدراسي</Form.Label>
                <Form.Control
                  name="education"
                  value={formData.education}
                  onChange={handleInputChange}
                  type="text"

                />
              </Form.Group>

              <Form.Group controlId="cv" className="mb-3">
                <Form.Label>السيرة الذاتية إن وجد</Form.Label>
                <Form.Control
                  name="cv"
                  value={formData.cv}
                  onChange={handleInputChange}
                  type="file"
                  accept=".pdf, .doc, .docx"
                />
              </Form.Group>
            </Col>
          </Row>
          <hr className={styles.hr} />
          <h4 className="mt-2 mb-4">بيانات التواصل</h4>
          <Row>
            <Col md={12}>
              <Form.Group className="mb-3" controlId="phone">
                <Form.Label>رقم الهاتف <span style={{color:"#f00"}}> * </span></Form.Label>

                <PhoneInput
                  country={"us"}
                  inputClass="ms-5"
                  style={{ border: fieldValidity.name ? "" : "1px solid red" , width: "fit-content" }}
                  value={valuePhone}
                  onChange = {setValuevaluePhone}
                  onKeyUp = {handleInputChange}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="wahtapp_num">
                <Form.Label>رقم واتساب <span style={{color:"#f00"}}> * </span></Form.Label>
                <PhoneInput
                  country={"us"}
                  inputClass="ms-5"
                  value={valueWahtsApp}
                  onChange={setvalueWahtsApp}
                  onKeyUp = {handleInputChange}
                  style={{ border: fieldValidity.name ? "" : "1px solid red" , width: "fit-content" }}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={12}>

            <Form.Group controlId="is_published_before" className="mb-3">
                <Form.Label>هل لديك مؤلفات تم نشرها من قبل؟ <span style={{color:"#f00"}}> * </span></Form.Label>
                <Form.Check
                  reverse
                  type="radio"
                  label="نعم"
                  value="yes"
                  name="is_published_before"
                  id="is_published_beforeYes"
                  onChange={handleInputChange}
                />
                <Form.Check
                  reverse
                  type="radio"
                  label="لا"
                  value="no"
                  name="is_published_before"
                  id="is_published_beforeNo"
                  onChange={handleInputChange}
                />
              </Form.Group>

              {formData.is_published_before === "yes" && (
                <div>
                  {formData.book_name?.map((bookName, index) => (
                    <Form.Group
                      key={index}
                      controlId={`book_name[${index}]`}
                      className="mb-3"
                    >
                      <Form.Label>إسم الكتاب {index + 1}</Form.Label>
                      <Form.Control
                        type="text"
                        value={bookName}
                        onChange={(e) =>
                          handleBookNameChange(index, e.target.value)
                        }
                        name={`book_name[${index}]`} // Add this line
                      />
                    </Form.Group>
                  ))}
                  <button
                    type="button"
                    onClick={handleAddBook}
                    className={`btn ${styles.btnPlus}`}
                  >
                    كتاب آخر
                  </button>
                </div>
              )}

              <Form.Group controlId="fav_contact" className="mb-3">
                <Form.Label>ما هي الطريقة الأفضل في التواصل؟ <span style={{color:"#f00"}}> * </span></Form.Label>
                <Form.Check
                  reverse
                  type="radio"
                  label="واتساب"
                  value="واتساب"
                  name="fav_contact"
                  id="fav_contactWhatssapp"
                  onChange={handleInputChange}
                />
                <Form.Check
                  reverse
                  type="radio"
                  label="هاتف"
                  value="هاتف"
                  name="fav_contact"
                  id="fav_contactMobile"
                  onChange={handleInputChange}
                />
                <Form.Check
                  reverse
                  type="radio"
                  label="البريد الإلكتروني"
                  value="البريد الإلكتروني"
                  name="fav_contact"
                  id="fav_contactEmail"
                  onChange={handleInputChange}
                />
              </Form.Group>


            </Col>
          </Row>

          <ReCAPTCHA
            hl="ar" 
        sitekey={recaptchaKey}
        onChange={handleRecaptchaChange}
      />

          <div className="text-center">
            <button type="submit" className={`btn ${styles.submitBtn}`}>
              حساب جديد
            </button>
          </div>
        </form>
      </Container>
    </>
  );
};

export default AuthSignUp;
