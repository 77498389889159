import React, { useEffect } from "react";
// import { Header } from "../layouts/header";
import { Col, Container, Row } from "react-bootstrap";
import styles from "../../styles/about.module.css";

//Assets
import headLogo from "../../assets/imgs/aboutLogo.png";
import aboutBulb from "../../assets/imgs/aboutBulb.png";
import { IoCheckmarkDoneOutline } from "react-icons/io5";
import aboutMission from "../../assets/imgs/aboutMission.png";
import logo from "../../assets/imgs/Linker_Logo.png";
import lasImg from "../../assets/imgs/lastImgAbout.png";
// import Footer from "../layouts/footer";
import { MdKeyboardDoubleArrowLeft } from "react-icons/md";
import { FaAsterisk } from "react-icons/fa";

const AboutUs = () => {
  useEffect(() => {
    document.title = "لينكر | من نحن";
  }, []);
  return (
    <>
      <div className={styles.mainIamge}>
        <img src={headLogo} alt="linkerLogo" className={styles.aboutLogo} />
      </div>

      <div className={styles.aboutSec2}>
        <Container>
          <Row>
            <Col className={styles.row1Sec} md={6}>
              <h1 className={styles.headingText}>من نحن ؟</h1>
              <p className={styles.paragraph}>
                منصة "لينكر" هي منصة حديثة الإنشاء وتعتبر الأولى من نوعها في
                الشرق الأوسط، تعمل على توفير كافة الخدمات التي تحتاجها صناعة
                النشر بشكل كامل ومتخصص.
              </p>

              <p className={styles.paragraph}>
                <IoCheckmarkDoneOutline className={styles.markSvg} />
                يتمكن أي صانع محتوى في مجال النشر أن يُنشئ حسابه الخاص على
                المنصة وعرض بيانات أعماله على أكبر عدد من الناشرين.
              </p>

              <p className={styles.paragraph}>
                <IoCheckmarkDoneOutline className={styles.markSvg} />
                تتمكن كل دار نشر من إنشاء حساب على المنصة تستطيع من خلاله التصفح
                ما بين كافة الأعمال المعروضة على المنصة، وإختيار العمل المناسب
                للنشر.
              </p>

              <p className={styles.paragraph}>
                <IoCheckmarkDoneOutline className={styles.markSvg} />
                يمكن لدار النشر أن تتواصل مع المؤلف مباشرةً بعد اختيار العمل.
              </p>

              <p className={styles.paragraph}>
              <FaAsterisk style={{fontSize: "37px"}} className={styles.markSvg} />
                كما ستتوافر قريبًا من خلال المنصة كافة التخصصات التي تحتاجها
                صناعة النشر مثل: المُدققين اللغوين، المُصممين،المُنسقين ،
                المُترجمين في مختلف اللغات والمُحررين الأدبيين وغيرها العديد من
                الخدمات التي تدعم صناعة النشر.
              </p>
            </Col>
            <Col
              className="d-flex align-item-center justify-content-center align-items-center "
              md={6}
            >
              <img src={aboutBulb} className={styles.bulb} alt="" />
            </Col>
          </Row>
        </Container>
      </div>

      <div className={styles.sec3}>
        <img src={aboutMission} alt="" />
      </div>
      <Container>
        <Row className="mb-5 mt-5">
          <div className="d-flex justify-content-center">
            <h1 className={styles.advantiage}>مميزات</h1>
            <img
              className={styles.advantiageImg}
              width="300px"
              src={logo}
              alt=""
            />
          </div>
        </Row>
      </Container>
      <Container className={styles.aboutDesktop}>
        <Row className="mb-5 mt-5">
          <Col className={styles.lastSecP} md={4}>
            <p>
              {" "}
              يقوم المؤلف بإرسال عدد كبير من رسائل البريد الإلكتروني لأكبر عدد
              من الناشرين باحثًا عن ناشر لكتابه.
            </p>
            <p>
              {" "}
              ينتظر فترة طويلة لحين الرد على طلبه، وقد لا يتم الرد عليه  خاصةً إذا
              كان عدد المتقدمين للنشر كبير.
            </p>
            <p> يهدر وقتًا كبيرًا في محاولة الوصول لدور النشر دون جدوى . </p>
          </Col>
          <Col className={styles.lastSecI} md={4}>
            <img src={lasImg} alt="" />
          </Col>
          <Col className={styles.lastSecP} md={4}>
            <p> يقوم المؤلف بإنشاء حساب له على المنصة في دقائق قليلة.</p>
            <p>
              {" "}
              يقوم برفع بيانات العمل المراد نشره من خلال المنصة في ضغطة واحدة
            </p>
            <p>
              {" "}
              تظهر بيانات العمل لأكبر عدد من الناشرين الراغبين في نشر هذا النوع
              من الأعمال، وتقوم المنصة بترشيح العمل للناشرين المختصين بالنشر في
              هذا المجال{" "}
            </p>
          </Col>
        </Row>
      </Container>

      <Container className={styles.aboutMobile}>
        <Row className="justify-content-center">
          <Col className={styles.before} sm={5}>
            <h1 className="text-center">قبل</h1>
            <div className="d-flex justify-content-center align-items-center">
              <p>
                {" "}
                يقوم المؤلف بإرسال عدد كبير من رسائل البريد الإلكتروني لأكبر عدد
                من الناشرين باحثاً عن ناشر لكتابه
              </p>
            </div>
            <hr />
            <div className="d-flex justify-content-center aligin-items-center">
              <p>
                {" "}
                ينتظر فترة طويلة لحين الرد على طلبه، وقد لا يتم الرد عليه خاصةً
                إذا كان عدد المتقدمين للنشر كبيرًا
              </p>
            </div>
            <hr />
            <div className="d-flex justify-content-center aligin-items-center">
              <p>يهدر وقتًا كبيرًا في محاولة الوصول لدور النشر دون جدوى</p>
            </div>
          </Col>
          <Col sm={1} className={styles.arrows}>
            <MdKeyboardDoubleArrowLeft />
            <MdKeyboardDoubleArrowLeft />
            <MdKeyboardDoubleArrowLeft />
          </Col>
          <Col className={styles.after} sm={5}>
            <h1 className="text-center">بعد</h1>
            <div className="d-flex justify-content-center aligin-items-center">
              <p> يقوم المؤلف بإنشاء حساب له على المنصة في دقائق قليلة.</p>
            </div>

            <hr />
            <div className="d-flex justify-content-center aligin-items-center">
              <p> يقوم برفع العمل المراد نشره من خلال المنصة في ضغطة واحدة.</p>
            </div>

            <hr />
            <div className="d-flex justify-content-center aligin-items-center">
              <p>
                {" "}
                تظهر بيانات العمل لأكبر عدد من الناشرين الراغبين في نشر هذا
                النوع من الأعمال، وتقوم المنصة بترشيح العمل للناشرين المختصين
                بالنشر في هذا المجال
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      {/* <Footer /> */}
    </>
  );
};

export default AboutUs;
