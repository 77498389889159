import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./pages/main/home";
import AboutUs from "./pages/main/about";
import SiginUpAs from "./pages/main/signUpAs";
import Footer from "./layouts/footer";
import { Header } from "./layouts/header";
import Login from "./pages/Auth/login";
import { AuthProvider } from "./context/AuthContext";
import AuthSignUp from "./pages/Auth/authSignUp";
import PubSignUp from "./pages/Auth/publSignUp";
import Books from "./pages/main/books";
import LookingFor from "./pages/main/lookingFor";
import SingleBook from "./pages/main/singleBook";
import AuthProfile from "./pages/Author/authProfile";
import AddNewBook from "./pages/Author/addNewBook";
import PubProfile from "./pages/Publisher/pubProfile";
import Contact from "./pages/main/contact";
import UpdateBook from "./pages/main/UpdateBook";
import ScrollToTop from "./layouts/scrollToTop";
import ForgetPassword from "./pages/Auth/ForgetPassword";
import NewPassword from "./pages/Auth/NewPassword";
import Books2 from "./pages/main/books2";
import AuthSignUp2 from "./pages/main/authSignup";

function App() {



 const  isAuthenticated = localStorage.getItem('isAuthenticated') === 'true';
  const isAuthor = localStorage.getItem('isAuthor') === 'true';
  const isPub = localStorage.getItem('isPub') === 'true';
  


  const routes = [
    { path: '/', element:  <Home />},
    { path: '/about', element:  <AboutUs /> },
    { path: '/contact', element:  <Contact /> },
    { path: '/books2', element:  <Books2 /> },

    { path: '/create-account', element: isAuthenticated ?  null : <SiginUpAs /> },
    { path: '/login', element: isAuthenticated ? null : <Login /> },
  
    { path: '/author-signup', element: isAuthenticated  ?  null : <AuthSignUp /> },
    { path: '/publisher-signup', element: isAuthenticated  ?  null : <PubSignUp /> },
  
    { path: '/books', element: isAuthenticated && isPub ? <Books /> : <Login /> },
  
    { path: '/lookingFor', element: isAuthenticated && isPub ? <LookingFor /> : <Login /> },
  
    { path: '/book/:bookId', element: isAuthenticated ? <SingleBook /> : <Login /> },
  
    { path: '/author-profile', element: isAuthenticated && isAuthor ? <AuthProfile /> : null },
  
    { path: '/update_Book/:book_title/:bookId/:book_description/:sub_cat/:book_number/:book_main_category', element: isAuthenticated && isAuthor ? <UpdateBook /> : <Login /> },
    { path: '/add-new', element: isAuthenticated && isAuthor ? <AddNewBook /> : <Login /> },
  
    { path: '/publisher-profile', element: isAuthenticated && isPub ? <PubProfile /> : null },

    { path: '/forget-password', element: isAuthenticated ? null : <ForgetPassword /> },
    
    { path: '/password-reset', element: isAuthenticated ? null : <NewPassword /> },
    { path: '/auth', element: <AuthSignUp2 /> },


    
  ];


  return (
  
    <AuthProvider>
  
    <div>
    {isAuthenticated}{isAuthor} {isPub}
    <BrowserRouter>
      <Header/>
      <ScrollToTop/>
      <Routes>
      {routes.map((route, index) => (
      <Route key={index} path={route.path} element={route.element} />
    ))}
      </Routes>
    <Footer/>
    </BrowserRouter>
  </div>

  </AuthProvider>
  );
}

export default App;
