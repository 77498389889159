import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import styles from "../../styles/form.module.css";
import "./Auth.css";
import "react-phone-input-2/lib/bootstrap.css";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";

import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import spinnerstyles from "../../styles/spinner.module.css";

import ReCAPTCHA from "react-google-recaptcha";

const PubSignUp = () => {
  const [loading, setLoading] = useState(false);

  //------------------Google ReCAPTCHA -----------------------------//
  // const [isNotRobot, setIsNotRobot] = useState(false);
  // const recaptchaKey = "6Lf9EVIpAAAAAKYXsDiCOGtM2VCv5bG7zfrkmZZ_";

  // const handleRecaptchaChange = (value) => {
  //   // This callback will be triggered when the user solves the reCAPTCHA.
  //   setIsNotRobot(true);
  // };
  //------------------Google ReCAPTCHA -----------------------------//

  const [Category, setCategory] = useState([]);

  //----------------DateTime From Date Input-----------------------------//
  function getCurrentDate() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }
  //----------------DateTime From Date Input-----------------------------//

  //----------------Get All Categories-----------------------------//

  const fetchData = async () => {
    try {
      const response = await axios.get(
        process.env.PUBLIC_URL + "/catygories.json",
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": "mwDA9w",
          },
        }
      );
      if (response.status === 200) {
        // console.log(response);
        setCategory(response.data.data);
      } else {
        console.error("Authentication failed:", response.data.message);
        // console.log(response);
      }
    } catch (error) {
      console.error("Error Fetching Categorys:", error.message);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  //----------------Get All Categories-----------------------------//

  const [formData, setFormData] = useState({
    password: "",
    password_confirmation: "",
    name: "",
    pub_name: "",
    country: "",
    local_pub_num: "",
    arab_pub_num: "",
    certificates: "",
    web_url: "",
    facebook_url: "",
    num_publihsed_books: "",
    categories_id: [""],
    email: "",
    number_titles_published_secondary: "",
    publish_doctoral_masters: "",
    care_translation: "",
    published_translated_books: "",
    count_post: "",
    interested_granting_translation: "",
    sold_translation_rights_to_any_of_your_publications: "",
    count_sold_translation: "",
    platform_nominate_books_offered_publication_favorite_specialty: "",
    branches_in_other_countries: "",
    country_name: [""],
  });

  //----------------Get All Countries--------------------//
  const [countries, setCountries] = useState([]);
  useEffect(() => {
    // Fetch the countries from the JSON file
    fetch(process.env.PUBLIC_URL + "/countries-arabic.json")
      .then((response) => response.json())
      .then((data) => {
        // Sort countries in Arabic alphabetical order
        const sortedCountries = sortCountries(data);
        setCountries(sortedCountries);
      })
      .catch((error) => console.error("Error fetching countries:", error));
  }, []);

  const sortCountries = (countries) => {
    return countries.sort((a, b) => a.name.localeCompare(b.name, "ar"));
  };
  //----------------Get All Countries--------------------//

  const [errorMessage, setErrorMessage] = useState("");

  const [isPublishedTransBook, setIsPublishTransBook] = useState("");
  const [soldTransGrant, setSoldTransGrant] = useState("");
  const [branches_in_other_countries, setbranches_in_other_countries] =
    useState("");

  //-----------Navigate------------//
  let navigate = useNavigate();
  //-----------Navigate------------//

  const handleAddCat = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      categories_id: [...prevFormData.categories_id, ""],
    }));
  };

  const [valuePhone, setValuevaluePhone] = useState();
  const [valueWahtsApp, setvalueWahtsApp] = useState();
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    //----------File Upload Handle-----------
    if (name === "certificates") {
      // File type validation logic
      const file = e.target.files[0];
      if (file) {
        if (
          file.type === "application/pdf" ||
          file.type === "application/msword"
        ) {
          setFormData({ ...formData, [name]: file });
        } else {
          setFormData({ ...formData, [name]: "" });
          toast(" Word , PDF الملف غير مقبول ! الملفات المقبولة هي ");
        }
      }
    }

    //----------File Upload Handle-----------

    if (
      name === "local_pub_num" ||
      name === "arab_pub_num" ||
      name === "number_titles_published_secondary" ||
      name === "count_post" ||
      name === "count_sold_translation"
    ) {
      setFormData({ ...formData, [name]: Number(value) });
    } else {
      setFormData({ ...formData, [name]: value });
    }

    if (name === "published_translated_books") {
      setIsPublishTransBook(e.target.value);
    }
    if (name === "sold_translation_rights_to_any_of_your_publications") {
      setSoldTransGrant(e.target.value);
    }
    if (name === "branches_in_other_countries") {
      setbranches_in_other_countries(e.target.value);
    }
    formData.phone = valuePhone;
    formData.wahtapp_num = valueWahtsApp;
  };

  const handlecountryChange = (index, value) => {
    const newcountryNames = [...formData.country_name];
    newcountryNames[index] = value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      country_name: newcountryNames,
    }));
  };
  const handleAddcountry = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      country_name: [...prevFormData.country_name, ""],
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let cat = formData.categories_id;
    let cot = formData.country_name;
    const categoryIdbject = formData.categories_id?.reduce(
      (acc, value, index) => {
        Category.forEach((el) => {
          if (el.name === value) {
            acc[index] = el.id;
            // return;
          }
        });

        return acc;
      },
      {}
    );

    const countryNameObject = formData.country_name?.reduce(
      (acc, value, index) => {
        acc[index] = value;
        return acc;
      },
      {}
    );

    formData.categories_id = categoryIdbject;
    formData.country_name = countryNameObject;
    formData.wahtapp_num = valueWahtsApp;
    formData.phone = valuePhone;
    const formDataWithIndexedBookNames = { ...formData };


    const apiKey = "mwDA9w";
    // formData.name = " أ ";
    const formDataToSend = { ...formData };

    if (formDataToSend.count_post === "") {
      delete formDataToSend.count_post;
    }

    if (formDataToSend.count_sold_translation === "") {
      delete formDataToSend.count_sold_translation;
    }
    // If the array is empty after filtering, delete the field
    if (formDataToSend.country_name[0] == "") {
      delete formDataToSend.country_name;
    }
    formDataToSend.phone = valuePhone.replace("+", "00");
    formDataToSend.wahtapp_num = valueWahtsApp.replace("+", "00");

    if (
      formDataWithIndexedBookNames.email.indexOf("@") !== -1 &&
      formDataWithIndexedBookNames.email.split("@")[1].indexOf(".") === -1
    ) {
      toast.error("البريد الإلكتروني غير صالح");
      return; // Stop the submission
    }
    console.log(formDataWithIndexedBookNames);
    // if (isNotRobot) {
      try {
        setLoading(true);
        const response = await axios.post(
          "https://linker-pub.com/linkerapis/public/api/register_publisher",
          formDataToSend,
          {
            headers: {
              "x-api-key": apiKey,
            },
          }
        );

        if (response.status == 200) {
          toast.success(" تم إنشاء الحساب بنجاح    ");
          // setTimeout(() => {
          //   navigate("/login");
          // }, 5000);
        } else {
          console.log("Something Wrong :", response);
        }
      
      } catch (error) {
        // console.log("Something Wrong :", error.response);
        toast.error(error.response.data.message);
        if (error.response.data.message == "This Phone Already Used Before") {
          toast.error("هذا الرقم مستخدم من قبل");
        } else if (
          error.response.data.message == "This E-Mail Already Used Before"
        ) {
          toast.error("البريد الإلكتروني مستخدم من قبل");
        } else if (
          error.response.data.message ==
          "Password & Its Confirmation Not Matching"
        ) {
          toast.error("كلمة المرور غير متطابقة  ");
        } else if (error.response.data.message == "Please Enter Your phone") {
          toast.error("أدخل رقم الهاتف");
        } else if (
          error.response.data.message ==
          "Password Must Be At Least 6 Characters"
        ) {
          toast.error("يجب أن لا تقل كلمة المرور عن 6 أحرف أو أرقام");
        } else if (error.response.data.message == "Please Enter Your Name") {
          toast.error("ادخل الإسم");
        } else if (
          error.response.data.message ==
          "The publish doctoral masters field is required."
        ) {
          toast.error("هل تفضل نشر رسايل الدكتوراه و الماجيستير مطلوب");
        } else if (
          error.response.data.message ==
          "The interested granting translation field is required."
        ) {
          toast.error("هل تهتم بمنح الترجمة ؟ مطلوب");
        } else if (
          error.response.data.message ==
          "The branches in other countries field is required"
        ) {
          toast.error("هل لديك فروع أخرى ؟ مطلوب");
        } else if (
          error.response.data.message ==
          "The care translation field is required."
        ) {
          toast.error("هل تهتم بالكتب المترجمة ؟ مطلوب");
        } else if (
          error.response.data.message ==
          "The published translated books field is required."
        ) {
          toast.error("هل نشرت كتب مترجمة من قبل مطلوب");
        } else if (
          error.response.data.message ==
          "The sold translation rights to any of your publications field is required."
        ) {
          toast.error("هل تم بيع حقوق ترجمة من قبل مطلوب");
        } else if (
          error.response.data.message ==
          "The platform nominate books offered publication favorite specialty field is required."
        ) {
          toast.error(
            "هل تريد المنصة أن ترشح لك كتب بناء علي التخصصات المفضلة مطلوب"
          );
        } else if (
          error.response.data.message ==
          "The branches in other countries field is required."
        ) {
          toast.error("هل لديك فروع أخرى ؟ مطلوب ");
        }
      } finally {
        setLoading(false);
      }
    // } else {
    //   toast.error("يرجى تأكيد أنك لست روبوت ");
    // }
    formData.categories_id = cat;
    formData.country_name = cot;
  };

  const handleCategoryChange = (index, value) => {
    const Category = [...formData.categories_id];
    Category[index] = value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      categories_id: Category,
    }));
  };

  useEffect(() => {
    document.title = "لينكر | حساب جديد";
  }, []);

  return (
    <>
      {loading && (
        <div className={spinnerstyles.overlay}>
          <div className={spinnerstyles.spinner}></div>
        </div>
      )}

      <ToastContainer />
      <h1 className={`mt-5 text-center ${styles.headerText}`}>سجل كناشر</h1>
      <Container className="mt-5">
        <form onSubmit={handleSubmit}>
          <h4 className="mt-2 mb-4">معلومات تسجيل الدخول</h4>
          {errorMessage && (
            <div className="alert alert-danger mt-3" role="alert">
              {errorMessage}
            </div>
          )}
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="email">
                <Form.Label>اسم المستخدم <span style={{color:"#f00"}}> * </span></Form.Label>
                <Form.Control
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                  type="text"
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="email">
                <Form.Label>البريد الإلكتروني <span style={{color:"#f00"}}> * </span></Form.Label>
                <Form.Control
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                  type="email"
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="password">
                <Form.Label>كلمة المرور <span style={{color:"#f00"}}> * </span></Form.Label>
                <Form.Control
                  name="password"
                  value={formData.password}
                  onChange={handleInputChange}
                  required
                  type="password"
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="password_confirmation">
                <Form.Label>تأكيد كلمة المرور <span style={{color:"#f00"}}> * </span></Form.Label>
                <Form.Control
                  name="password_confirmation"
                  value={formData.password_confirmation}
                  onChange={handleInputChange}
                  type="password"
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="arab_pub_num">
                <Form.Label>رقم عضوية اتحاد الناشرين العرب</Form.Label>
                <Form.Control
                  name="arab_pub_num"
                  value={formData.arab_pub_num}
                  onChange={handleInputChange}
                  type="number"
                />
              </Form.Group>

              {Array.isArray(formData.categories_id) &&
                formData.categories_id.map((categories_id, index) => (
                  <Form.Group
                    className="mb-3"
                    controlId="living_home"
                    key={index}
                    required
                  >
                    <Form.Label> التخصصات المفضلة <span style={{color:"#f00"}}> * </span></Form.Label>
                    <select
                      className="form-select"
                      name="living_home"
                      value={categories_id}
                      required
                      onChange={(e) => {
                        handleInputChange(e);
                        handleCategoryChange(index, e.target.value);
                      }}
                    >
                      <option value=""> اختر تخصص </option>
                      {Category?.filter(
                        (Category) => Category.sub_categories.length !== 0
                      ).map((Category) => (
                        <option key={Category.code} value={Category.name}>
                          {Category.name}
                        </option>
                      ))}
                    </select>
                  </Form.Group>
                ))}
              <button
                type="button"
                onClick={handleAddCat}
                className={`btn ${styles.btnPlus}`}
              >
                تخصص آخر
              </button>
            </Col>

            <Col md={6}>
              <Form.Group className="mb-3" controlId="name">
                <Form.Label>  اسم دار النشر <span style={{color:"#f00"}}> * </span></Form.Label>
                <Form.Control
                  name="pub_name"
                  value={formData.pub_name}
                  onChange={handleInputChange}
                  type="text"
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="name">
                <Form.Label>البلد <span style={{color:"#f00"}}> * </span> </Form.Label>
                <Form.Select
                  name="country"
                  value={formData.country}
                  onChange={handleInputChange}
                  required
                >
                  <option value="" disabled>
                    Select a country
                  </option>
                  {countries.map((country) => (
                    <option key={country.code} value={country.name}>
                      {country.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-3" controlId="birthDatePub">
                <Form.Label>سنة التأسيس <span style={{color:"#f00"}}> * </span></Form.Label>
                <Form.Control
                  name="birthDatePub"
                  value={formData.birthDatePub}
                  onChange={handleInputChange}
                  max={getCurrentDate()}
                  type="date"
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="local_pub_num">
                <Form.Label> رقم عضوية اتحاد الناشرين المحلي</Form.Label>
                <Form.Control
                  name="local_pub_num"
                  value={formData.local_pub_num}
                  onChange={handleInputChange}
                  type="number"
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="num_publihsed_books">
                <Form.Label>عدد العناوين المنشورة <span style={{color:"#f00"}}> * </span></Form.Label>
                <Form.Control
                  name="num_publihsed_books"
                  value={formData.num_publihsed_books}
                  onChange={handleInputChange}
                  type="number"
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="certificates">
                <Form.Label>الجوائز ان وجد</Form.Label>
                <Form.Control
                  name="certificates"
                  value={formData.certificates}
                  onChange={handleInputChange}
                  type="file"
                  accept=".pdf, .doc, .docx"
                />
              </Form.Group>
            </Col>
          </Row>

          <hr className={styles.hr} />
          <h4 className="mt-2 mb-4">بيانات التواصل</h4>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="web_url">
                <Form.Label>الموقع الإلكتروني </Form.Label>
                <Form.Control
                  name="web_url"
                  value={formData.web_url}
                  onChange={handleInputChange}
                  type="text"
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="facebook">
                <Form.Label>الفيسبوك</Form.Label>
                <Form.Control
                  name="facebook_url"
                  value={formData.facebook_url}
                  onChange={handleInputChange}
                  type="text"
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="phone">
                <Form.Label>رقم الهاتف <span style={{color:"#f00"}}> * </span></Form.Label>

                <PhoneInput
                  country={"us"}
                  inputClass="ms-5"
                  value={valuePhone}
                  onChange={setValuevaluePhone}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="wahtapp_num">
                <Form.Label>رقم واتساب <span style={{color:"#f00"}}> * </span> </Form.Label>
                <PhoneInput
                  country={"us"}
                  inputClass="ms-5"
                  value={valueWahtsApp}
                  onChange={setvalueWahtsApp}
                />
              </Form.Group>
              {/*
              
                <Form.Control
                  name="wahtapp_num"
                  value={formData.wahtapp_num}
                  onChange={handleInputChange}
                  type="number"
                />
              </Form.Group> */}
            </Col>
          </Row>

          <hr className={styles.hr} />
          <h4 className="mt-2 mb-4">اسئلة حول الناشر</h4>

          <Row>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="website">
                <Form.Label>عدد العناوين المنشورة سنويًا  <span style={{color:"#f00"}}> * </span></Form.Label>
                <Form.Control
                  name="number_titles_published_secondary"
                  value={formData.number_titles_published_secondary}
                  onChange={handleInputChange}
                  type="number"
                  required
                />
              </Form.Group>

              <Form.Group controlId="publish_doctoral_masters" className="mb-3">
                <Form.Label>
                  هل تفضل نشر رسايل الدكتوراه و الماجيستير ؟ <span style={{color:"#f00"}}> * </span>
                </Form.Label>
                <Form.Check
                  reverse
                  type="radio"
                  label="نعم"
                  value="yes"
                  name="publish_doctoral_masters"
                  id="publish_doctoral_mastersYes"
                  onChange={handleInputChange}
                />
                <Form.Check
                  reverse
                  type="radio"
                  label="لا"
                  value="no"
                  name="publish_doctoral_masters"
                  id="publish_doctoral_mastersNo"
                  onChange={handleInputChange}
                />
              </Form.Group>

              <Form.Group controlId="care_translation" className="mb-3">
                <Form.Label> هل تهتم بالترجمة ؟ <span style={{color:"#f00"}}> * </span></Form.Label>
                <Form.Check
                  reverse
                  type="radio"
                  label="نعم"
                  value="yes"
                  name="care_translation"
                  id="care_translationYes"
                  onChange={handleInputChange}
                />
                <Form.Check
                  reverse
                  type="radio"
                  label="لا"
                  value="no"
                  name="care_translation"
                  id="care_translationNo"
                  onChange={handleInputChange}
                />
              </Form.Group>

              {/* Done */}
              <Form.Group
                controlId="published_translated_books"
                className="mb-3"
              >
                <Form.Label>هل نشرت كتب مترجمة من قبل ؟ <span style={{color:"#f00"}}> * </span></Form.Label>
                <Form.Check
                  reverse
                  type="radio"
                  label="نعم"
                  value="yes"
                  name="published_translated_books"
                  id="published_translated_booksYes"
                  onChange={handleInputChange}
                />
                <Form.Check
                  reverse
                  type="radio"
                  label="لا"
                  value="no"
                  name="published_translated_books"
                  id="published_translated_booksNo"
                  onChange={handleInputChange}
                />
              </Form.Group>

              {isPublishedTransBook === "yes" && (
                <div>
                  <Form.Group className="mb-3" controlId="count_post">
                    <Form.Label> كم عدد الكتب المنشورة من قبل؟ <span style={{color:"#f00"}}> * </span></Form.Label>
                    <Form.Control
                      name="count_post"
                      value={formData.count_post}
                      onChange={handleInputChange}
                      type="number"
                    />
                  </Form.Group>
                </div>
              )}

              {/* Done */}
              <Form.Group
                controlId="interested_granting_translation"
                className="mb-3"
              >
                <Form.Label>هل تهتم بمنح الترجمة ؟ <span style={{color:"#f00"}}> * </span></Form.Label>
                <Form.Check
                  reverse
                  type="radio"
                  label="نعم"
                  value="yes"
                  name="interested_granting_translation"
                  id="interested_granting_translationYes"
                  onChange={handleInputChange}
                />
                <Form.Check
                  reverse
                  type="radio"
                  label="لا"
                  value="no"
                  name="interested_granting_translation"
                  id="interested_granting_translationNo"
                  onChange={handleInputChange}
                />
              </Form.Group>

              {/* Done */}
              <div>
                <Form.Group
                  controlId="sold_translation_rights_to_any_of_your_publications"
                  className="mb-3"
                >
                  <Form.Label>هل تم بيع حقوق ترجمة من قبل ؟ <span style={{color:"#f00"}}> * </span></Form.Label>
                  <Form.Check
                    reverse
                    type="radio"
                    label="نعم"
                    value="yes"
                    name="sold_translation_rights_to_any_of_your_publications"
                    id="sold_translation_rights_to_any_of_your_publicationsYes"
                    onChange={handleInputChange}
                  />
                  <Form.Check
                    reverse
                    type="radio"
                    label="لا"
                    value="no"
                    name="sold_translation_rights_to_any_of_your_publications"
                    id="sold_translation_rights_to_any_of_your_publicationsNo"
                    onChange={handleInputChange}
                  />
                </Form.Group>

                {soldTransGrant === "yes" && (
                  <div>
                    <Form.Group className="mb-3" controlId="count_post">
                      <Form.Label>
                        {" "}
                        كم عدد الكتب تم بيع حقوق ترجمتها من قبل ؟{" "}<span style={{color:"#f00"}}> * </span>
                      </Form.Label>
                      <Form.Control
                        name="count_sold_translation"
                        value={formData.count_sold_translation}
                        onChange={handleInputChange}
                        type="number"
                      />
                    </Form.Group>
                  </div>
                )}
              </div>

              {/* Done */}
              <Form.Group
                controlId="platform_nominate_books_offered_publication_favorite_specialty"
                className="mb-3"
              >
                <Form.Label>
                  هل تريد المنصة أن ترشح لك كتب بناءً علي التخصصات المفضلة التي  قمت بإدخالها ؟{" "} <span style={{color:"#f00"}}> * </span>
                </Form.Label>
                <Form.Check
                  reverse
                  type="radio"
                  label="نعم"
                  value="yes"
                  name="platform_nominate_books_offered_publication_favorite_specialty"
                  id="platform_nominate_books_offered_publication_favorite_specialtyYes"
                  onChange={handleInputChange}
                />
                <Form.Check
                  reverse
                  type="radio"
                  label="لا"
                  value="no"
                  name="platform_nominate_books_offered_publication_favorite_specialty"
                  id="platform_nominate_books_offered_publication_favorite_specialtyNo"
                  onChange={handleInputChange}
                />
              </Form.Group>

              {/* Done */}
              <Form.Group
                controlId="branches_in_other_countries"
                className="mb-3"
              >
                <Form.Label>هل لدي الدار فروع أخرى ؟  <span style={{color:"#f00"}}> * </span></Form.Label>
                <Form.Check
                  reverse
                  type="radio"
                  label="نعم"
                  value="yes"
                  name="branches_in_other_countries"
                  id="branches_in_other_countriesYes"
                  onChange={handleInputChange}
                />
                <Form.Check
                  reverse
                  type="radio"
                  label="لا"
                  value="no"
                  name="branches_in_other_countries"
                  id="branches_in_other_countriesNo"
                  onChange={handleInputChange}
                />
              </Form.Group>

              {branches_in_other_countries === "yes" && (
                <div>
                  {Array.isArray(formData.country_name) && formData.country_name?.map((countryName, index) => (
                    <div>
                      <Form.Group className="mb-3" controlId="nationalty">
                        <Form.Label>البلد</Form.Label>

                        <select
                          className="form-select"
                          value={countryName}
                          onChange={(e) =>
                            handlecountryChange(index, e.target.value)
                          }
                          name={`country_name[${index}]`}
                        >
                          <option value="">Select a nationalty</option>
                          {countries.map((country) => (
                            <option key={country.code} value={country.name}>
                              {country.name}
                            </option>
                          ))}
                        </select>
                      </Form.Group>
                      {/* <Form.Group
                      key={index}
                      controlId={`country_name[${index}]`}
                      className="mb-3"
                    >
                      <Form.Label>إسم الفرع {index + 1}</Form.Label>
                      <Form.Control
                        type="text"
                        value={countryName}
                        onChange={(e) =>
                          handlecountryChange(index, e.target.value)
                        }
                        name={`country_name[${index}]`} 
                      />
                    </Form.Group> */}
                    </div>
                  ))}

                  <button
                    type="button"
                    onClick={handleAddcountry}
                    className={`btn ${styles.btnPlus}`}
                  >
                    فرع آخر
                  </button>
                </div>
              )}
            </Col>
          </Row>

          {/* <ReCAPTCHA
            hl="ar"
            sitekey={recaptchaKey}
            onChange={handleRecaptchaChange}
          /> */}

          <div className="text-center">
            <button type="submit" className={`btn ${styles.submitBtn}`}>
              حساب جديد
            </button>
          </div>
        </form>
      </Container>
    </>
  );
};

export default PubSignUp;
