import React, { useEffect, useState } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';

import axios from "axios";
import { useNavigate } from "react-router-dom";
const UpdatePubProfile = ({ fullName,email,phone }) => {
  
    console.log(fullName,email,phone )

  const isAuthor = localStorage.getItem("isAuthor");
  console.log(isAuthor)
    let navigate = useNavigate()
  /*Post Api Vars */
  const [Name, setName] = useState("");
  const [Email, setEmail] = useState("");
  const [Phone, setPhone] = useState("");
  console.log(Name,Email,Phone )

  const Token = localStorage.getItem("Token");


  useEffect(() => {
    setName(fullName)
    setEmail(email)
    setPhone(phone)

  }, []);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
        let data = {
            name: Name,
            email: Email,
            phone: Phone,
        }
      console.log(Name,Email,Phone )
      const response = await axios.post(
        "https://linker-pub.com/linkerapis/public/api/update_profile_author",data,
        {
          headers: {
            Authorization: `Bearer ${Token}`,
            "x-api-key": "mwDA9w",
          },
        }
      );
        console.log(response);
        if(response.data.success){
          toast("تم تعديل البيانات بنجاح");
          
        }

    }

    useEffect(() => {
      document.title = "لينكر | حسابي";
    }, []);

  return (
    <div>
    <ToastContainer />
      {/* {shouldRender  ? ( */}
        <>
          <Container className="mt-5">
            <Row className="justify-content-center">
              <Col className="m-auto" md={{ span: 6, offset: 3 }}>
                <h2>تعديل بيانات دار النشر </h2>
                <Form>
                  <Form.Group controlId="bookName" style={{display:"none"}}>
                    <Form.Label>اسم المؤلف </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="اضف  اسم"
                      name="bookName"
                      value={Name}
                      onChange={(e) => setName(e.target.value)}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="bookName">
                    <Form.Label>البريد الالكتروني  </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="اضف البريد الالكتروني"
                      name="bookName"
                      value={Email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="bookName">
                    <Form.Label>رقم الهاتف</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="اضف رقم الهاتف"
                      name="bookName"
                      value={Phone}
                      onChange={(e) => setPhone(e.target.value)}
                      required
                    />
                  </Form.Group>


                  

                  <div className="mt-5 text-center">
                    <Button
                      onClick={handleFormSubmit}
                      className="btn btn-primary mb-5 w-50"
                      variant="primary"
                      type="submit"
                    >
                      تعديل
                    </Button>
                  </div>
                </Form>
              </Col>
            </Row>
          </Container>
        </>
 
    </div>
  );
};

export default UpdatePubProfile;
